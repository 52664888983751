<script>
  export let register = false

  import Gravatar from 'svelte-gravatar'
  import RouterLink from '@spaceavocado/svelte-router/component/link'
  import { Toast, Button, Field, Input, Select, Collapse, Notification } from 'svelma'
  import Loader from '../components/Loader.svelte'
  import MiddleBox from '../components/MiddleBox.svelte'
  import ErrorBox from '../components/ErrorBox.svelte'
  import { apiCall } from '../lib/api'
  import { router } from '@spaceavocado/svelte-router'
  import { appInfo } from '../lib/appInfo'
  import { onMount } from 'svelte'
  import { escape } from '../lib/utils'

  let loading = false

  let profile
  let fieldErrors = {}
  let duplicate = false

  let formElement

  if (register) {
    onMount(() => {
      if (formElement) formElement.querySelector('input').focus() // Because autofocus doesn't properly work on <Input>
    })
  }

  const dummyId = register ? Math.floor(Math.random() * 1e10).toString() : 'dummy'

  let passwordChangeSectionOpen = false
  const incompleteProfile = appInfo.incompleteProfile || register

  async function load () {
    if (register) {
      profile = {}
    } else {
      profile = await apiCall('GET', '/profile')
    }
  }

  async function save () {
    loading = true
    fieldErrors = {}
    try {
      profile = register ? await apiCall('POST', '/register', profile) : await apiCall('PATCH', '/profile', profile)
      Toast.create({ message: register ? 'User account created successfully!' : 'Profile saved successfully!', type: 'is-success' })

      profile.oldPassword = ''
      profile.password = ''
      profile.passwordConfirmation = ''

      if (register || profile.username !== appInfo.username || profile.email !== appInfo.email || appInfo.incompleteProfile) {
        location.assign('/')
      } else {
        $router.push({ name: 'HOME' })
      }
    } catch (e) {
      Toast.create({ message: escape(e.serverErrorMessage) || 'Saving profile failed, please try again', type: 'is-danger' })
      fieldErrors = (e.responseBody && e.responseBody.fieldErrors) || {}
      duplicate = !!(e.responseBody && e.responseBody.duplicate)
      loading = false
    }
  }
</script>

<svelte:head>
  <title>{register ? 'Register' : 'Profile'} - CherryShare</title>
</svelte:head>

<!-- Note: The is-danger class shouldn't be necessary on the <Input> as per Svelma docs, but it actually is due to a bug: https://github.com/c0bra/svelma/issues/56 -->

<div class="container">
  {#if register && !navigator.cookieEnabled}
    <ErrorBox text="Sorry, cookies need to be enabled to register a CherryShare account!">
      It's possible to view other people's files without cookies enabled, but in order to create an account and log in, cookies must be enabled so that the login session can be stored.<br />
      <br />
      Please enable cookies and then <a href={undefined} on:click={() => location.reload()}>reload the page</a>.
    </ErrorBox>
  {:else}
    {#await load()}
      <Loader />
    {:then}
      {#if incompleteProfile && !register}
        <Notification type="is-info">
          <strong>Your profile is incomplete!</strong><br />
          <br />
          You need to complete your profile and agree to the rules in order to be allowed to upload new files to CherryShare.
        </Notification>
      {/if}

      {#if register && duplicate}
        <Notification type="is-info">
          Maybe you already have an account? Try <RouterLink to={{ name: 'login' }}>logging in</RouterLink> instead!
        </Notification>
      {/if}

      <MiddleBox>
        <div class="has-text-centered m-2">
          <figure class="image is-128x128 is-inline-block">
            <Gravatar email={profile.email || `user${appInfo.userId || dummyId}@cherryshare.at`} size=128 default="monsterid" rating="pg" class="is-rounded" />
          </figure>
          <br />
          {#if register}
            Welcome to CherryShare!
          {:else}
            Member since {new Date(profile.createdAt || 0).toLocaleDateString()}
          {/if}
        </div>

        <form on:submit|preventDefault={save} bind:this={formElement}>
          <fieldset disabled={loading}>
            <Field label="Username" type={fieldErrors.username ? 'is-danger' : ''} message={fieldErrors.username}>
              <Input type="text" bind:value={profile.username} icon="user" class={fieldErrors.username ? 'is-danger' : ''} />
            </Field>

            <Field label="Email address" type={fieldErrors.email ? 'is-danger' : ''} message={fieldErrors.email}>
              <Input type="email" bind:value={profile.email} icon="at" class={fieldErrors.email ? 'is-danger' : ''} />
            </Field>

            <Field label="In which RPG Maker community are you most active?" type={fieldErrors.community ? 'is-danger' : ''} message={fieldErrors.community || 'You MUST be part of one of these communities to upload files to CherryShare. This is a private service for a small group of people.'}>
              <Select bind:selected={profile.community} icon="users" iconPack="fas" type={fieldErrors.community ? 'is-danger' : ''} expanded placeholder="- Select -">
                {#each Object.entries(appInfo.communityMap) as [key, text]}
                  <option value={key}>{text}</option>
                {/each}
              </Select>
            </Field>

            <Field label={`Your nickname at ${appInfo.communityMap[profile.community] || 'the selected community'}`} type={fieldErrors.communityNickname ? 'is-danger' : ''} message={fieldErrors.communityNickname || 'Please be aware that you will get banned in case you put a bogus info here.'}>
              <Input type="text" bind:value={profile.communityNickname} icon="address-card" class={fieldErrors.communityNickname ? 'is-danger' : ''} />
            </Field>

            {#if register}
              <Field label="Password" type={fieldErrors.password ? 'is-danger' : ''} message={fieldErrors.password}>
                <Input type="password" bind:value={profile.password} icon="star-of-life" class={fieldErrors.password ? 'is-danger' : ''} />
              </Field>

              <Field label="Repeat password" type={fieldErrors.passwordConfirmation ? 'is-danger' : ''} message={fieldErrors.passwordConfirmation}>
                <Input type="password" bind:value={profile.passwordConfirmation} icon="star-of-life" class={fieldErrors.passwordConfirmation ? 'is-danger' : ''} />
              </Field>
            {:else}
              <Collapse bind:open={passwordChangeSectionOpen}>
                <a href={undefined} slot="trigger">{passwordChangeSectionOpen ? 'Hide password changing option' : 'Change password'}</a>

                <Field label="CURRENT password" type={fieldErrors.oldPassword ? 'is-danger' : ''} message={fieldErrors.oldPassword}>
                  <Input type="password" bind:value={profile.oldPassword} icon="star-of-life" class={fieldErrors.oldPassword ? 'is-danger' : ''} />
                </Field>

                <Field label="NEW password" type={fieldErrors.password ? 'is-danger' : ''} message={fieldErrors.password}>
                  <Input type="password" bind:value={profile.password} icon="star-of-life" class={fieldErrors.password ? 'is-danger' : ''} />
                </Field>

                <Field label="Repeat NEW password" type={fieldErrors.passwordConfirmation ? 'is-danger' : ''} message={fieldErrors.passwordConfirmation}>
                  <Input type="password" bind:value={profile.passwordConfirmation} icon="star-of-life" class={fieldErrors.passwordConfirmation ? 'is-danger' : ''} />
                </Field>
              </Collapse>
            {/if}

            {#if incompleteProfile}
              <hr />

              <Field label="Enter the name of something I (Cherry) am known for in the community" type={fieldErrors.cherryProject ? 'is-danger' : ''} message={fieldErrors.cherryProject || 'This question exists just to verify that you are "one of us"'}>
                <Input type="text" bind:value={profile.cherryProject} icon="question" class={fieldErrors.cherryProject ? 'is-danger' : ''} />
              </Field>

              <div class="control">
                <label class="checkbox">
                  <input type="checkbox" bind:checked={profile.agreeToRules} class:is-danger={!!fieldErrors.agreeToRules}>
                  I have read the <a href="/rules" target="_blank">rules</a> and I will follow them
                </label>
                {#if fieldErrors.agreeToRules}
                  <p class="help is-danger">{fieldErrors.agreeToRules}</p>
                {/if}
              </div>
            {/if}

            <br />

            <Field>
              <Button type="is-primary" nativeType="submit" {loading}>{register ? 'Register' : 'Save'}</Button>
            </Field>
          </fieldset>
        </form>
      </MiddleBox>
    {:catch error}
      <ErrorBox text="Failed to load profile">{error}</ErrorBox>
    {/await}
  {/if}
</div>
