<script>
  export let maxPage
  export let currentPage

  import { createEventDispatcher } from 'svelte'
  const dispatch = createEventDispatcher()

  let pageEntries = []
  $: {
    const displayedPages = new Set()
    for (let i = 0; i < 3; i++) {
      displayedPages.add(i)
      displayedPages.add(currentPage - i)
      displayedPages.add(currentPage + i)
      displayedPages.add(maxPage - i)
    }
    const showFirstEllipsis = maxPage >= 6 && !displayedPages.has(3)
    const showSecondEllipsis = maxPage >= 6 && !displayedPages.has(maxPage - 3)
    pageEntries = Array.from(displayedPages).filter(p => p >= 0 && p <= maxPage).sort((a, b) => a - b)
    if (showFirstEllipsis) pageEntries.splice(3, 0, null)
    if (showSecondEllipsis && pageEntries[pageEntries.length - 4] !== null) pageEntries.splice(-3, 0, null)
    pageEntries = pageEntries
  }

  function go (page) {
    if (page < 0 || page > maxPage) return
    currentPage = page
    dispatch('change', page)
  }
</script>

<nav class="pagination is-centered {$$props.class}" role="navigation" aria-label="pagination">
  <a href={undefined} class="pagination-previous" {...currentPage <= 0 ? { disabled: 'disabled' } : {}} on:click={() => go(currentPage - 1)}><span class="icon"><i class="fa fa-arrow-left"></i></span> <span>Previous</span></a>
  <a href={undefined} class="pagination-next" {...currentPage >= maxPage ? { disabled: 'disabled' } : {}} on:click={() => go(currentPage + 1)}><span class="icon"><i class="fa fa-arrow-right"></i></span> <span>Next</span></a>
  <ul class="pagination-list">
    {#each pageEntries as page}
      {#if page === null}
        <li><span class="pagination-ellipsis">&hellip;</span></li>
      {:else if page === currentPage}
        <li><a href={undefined} class="pagination-link is-current" aria-label="Page {page + 1}" aria-current="page">{page + 1}</a></li>
      {:else}
        <li><a href={undefined} class="pagination-link" aria-label="Goto page {page + 1}" on:click={() => go(page)}>{page + 1}</a></li>
      {/if}
    {/each}
  </ul>
</nav>
