<script>
  export let logout = false

  import RouterLink from '@spaceavocado/svelte-router/component/link'
  import { router } from '@spaceavocado/svelte-router'
  import { onDestroy } from 'svelte'

  let backTo

  function updateBackTo (route) {
    backTo = (route && route.fullPath) || undefined
    if (!backTo) return
    if (backTo === '/' || backTo.startsWith('/login') || backTo.startsWith('/signup')) backTo = undefined
  }

  updateBackTo($router.currentRoute)
  let unregister = $router.onNavigationChanged((from, to) => updateBackTo(to))

  onDestroy(() => {
    unregister()
    unregister = null
  })
</script>

<RouterLink {...$$props} to={{ name: logout ? 'logout' : 'login', query: { ...backTo ? { backTo: encodeURIComponent(backTo) } : {} } }}>
  <slot />
</RouterLink>
