<script>
  export let file
  export let linkPrefs = {
    embedMode: 'forum',
    imageLinkMode: 'thumbnail',
    linkType: 'short'
  }

  import { Toast } from 'svelma'
  import { appInfo } from '../lib/appInfo'

  try {
    linkPrefs = JSON.parse(localStorage.linkPrefs) || {}
  } catch (e) {}

  import { createEventDispatcher } from 'svelte'

  const dispatch = createEventDispatcher()

  function setLinkPrefs (obj) {
    Object.assign(linkPrefs, obj)
    linkPrefs = linkPrefs // Trigger update
    dispatch('change', linkPrefs)
    localStorage.linkPrefs = JSON.stringify(linkPrefs)
  }

  let filenameRegex = /[^0-9A-Za-zÀ-ÖØ-öø-ÿ.,-]/g
  try {
    // We cannot write /[^0-9\p{L}\p{M}.,-]/gu as literal because its *existence* in the file will already trip up certain Firefox/Waterfox versions
    filenameRegex = new RegExp('[^0-9\\p{L}\\p{M}.,-]', 'gu') // eslint-disable-line prefer-regex-literals
  } catch (e) {}

  $: urlFriendlyFilename = file.current.filename.replace(filenameRegex, '_') // This then won't be further encoded
  $: fUrl = `${appInfo.appUrl}/f/${file.id}${linkPrefs.linkType === 'long' ? '/' + urlFriendlyFilename : ''}`
  $: iUrl = `${appInfo.appUrl}/i/${file.id}${linkPrefs.linkType === 'long' ? '/' + urlFriendlyFilename : ''}` + (file.current.version !== 1 ? `?v=${file.current.version}` : '')
  $: iUrlEmbed = `${appInfo.appUrl}/i/${file.id}` + (file.current.version !== 1 ? `?v=${file.current.version}` : '')
  $: tUrlEmbed = `${appInfo.appUrl}/t/${file.id}` + (file.current.version !== 1 ? `?v=${file.current.version}` : '')
  let embedCode = ''
  $: {
    if (linkPrefs.embedMode === 'forum') {
      embedCode = `[url="${fUrl}"]`
      const encodedTitle = (file.title || file.current.filename).replace(/\[/g, '(').replace(/\]/g, ')')
      if (linkPrefs.imageLinkMode === 'text' || !file.hotlinkable) {
        embedCode += encodedTitle
      } else {
        embedCode += `[img]${linkPrefs.imageLinkMode === 'thumbnail' ? tUrlEmbed : iUrlEmbed}[/img]`
      }
      embedCode += '[/url]'
    } else if (linkPrefs.embedMode === 'markdown') {
      const encodedTitle = (file.title || file.current.filename).replace(/\[/g, '\\[').replace(/\]/g, '\\]')
      embedCode = '['
      if (linkPrefs.imageLinkMode === 'text' || !file.hotlinkable) {
        embedCode += encodedTitle
      } else {
        embedCode += `![${encodedTitle}](${linkPrefs.imageLinkMode === 'thumbnail' ? tUrlEmbed : iUrlEmbed})`
      }
      embedCode += `](${fUrl})`
    } else if (linkPrefs.embedMode === 'html') {
      const encodedTitle = (file.title || file.current.filename).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
      embedCode = `<a href="${fUrl}">`
      if (linkPrefs.imageLinkMode === 'text' || !file.hotlinkable) {
        embedCode += encodedTitle
      } else {
        embedCode += `<img src="${linkPrefs.imageLinkMode === 'thumbnail' ? tUrlEmbed : iUrlEmbed}" alt="${encodedTitle}">`
      }
      embedCode += '</a>'
    }
  }

  function smartSelect (e) {
    if (this.selectionStart === this.selectionEnd) {
      this.select()
      if (tryCopy()) Toast.create({ message: 'Copied to clipboard', type: 'is-success' })
    }
  }

  function tryCopy () {
    try {
      return document.execCommand('copy') // Security exception may be thrown by some browsers.
    } catch (e) {
      console.warn('Copying to clipboard failed', e)
      return false
    }
  }
</script>

<div class="is-flex is-justify-content-center is-align-items-space-around">
  <div class="m-2">
    <div class="buttons are-small has-addons">
      <button class="button {linkPrefs.embedMode === 'forum' ? 'is-primary is-selected' : ''}" on:click={() => setLinkPrefs({ embedMode: 'forum' })}>Forum code</button>
      <button class="button {linkPrefs.embedMode === 'markdown' ? 'is-primary is-selected' : ''}" on:click={() => setLinkPrefs({ embedMode: 'markdown' })}>Markdown</button>
      <button class="button {linkPrefs.embedMode === 'html' ? 'is-primary is-selected' : ''}" on:click={() => setLinkPrefs({ embedMode: 'html' })}>HTML</button>
    </div>
  </div>

  {#if file.hotlinkable}
    <div class="m-2">
      <div class="buttons are-small has-addons">
        <button class="button {linkPrefs.imageLinkMode === 'text' ? 'is-primary is-selected' : ''}" on:click={() => setLinkPrefs({ imageLinkMode: 'text' })}>Text link</button>
        <button class="button {linkPrefs.imageLinkMode === 'thumbnail' ? 'is-primary is-selected' : ''}" on:click={() => setLinkPrefs({ imageLinkMode: 'thumbnail' })}>Thumbnail</button>
        <button class="button {linkPrefs.imageLinkMode === 'original' ? 'is-primary is-selected' : ''}" on:click={() => setLinkPrefs({ imageLinkMode: 'original' })}>Full size</button>
      </div>
    </div>
  {/if}

  <div class="m-2">
    <div class="buttons are-small has-addons">
      <button class="button {linkPrefs.linkType === 'short' ? 'is-primary is-selected' : ''}" on:click={() => setLinkPrefs({ linkType: 'short' })}>Short links</button>
      <button class="button {linkPrefs.linkType === 'long' ? 'is-primary is-selected' : ''}" on:click={() => setLinkPrefs({ linkType: 'long' })}>With filename</button>
    </div>
  </div>
</div>

<fieldset class="mt-2">
  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="label">File page URL</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <input class="input" readonly value={fUrl} on:mouseup={smartSelect} />
        </div>
      </div>
    </div>
  </div>

  {#if file.hotlinkable}
    <div class="field is-horizontal">
      <div class="field-label is-normal">
        <!-- svelte-ignore a11y-label-has-associated-control -->
        <label class="label">Image hotlink URL</label>
      </div>
      <div class="field-body">
        <div class="field">
          <div class="control">
            <input class="input" readonly value={iUrl} on:mouseup={smartSelect} />
          </div>
          {#if file.current.version !== 1}
            <p class="help">If you have existing links without the <code>?v={file.current.version}</code> part, don't worry, they still work - they will just take a few minutes longer to update due to caching.</p>
          {/if}
        </div>
      </div>
    </div>
  {/if}

  <div class="field is-horizontal">
    <div class="field-label is-normal">
      <!-- svelte-ignore a11y-label-has-associated-control -->
      <label class="label">{{ forum: 'Forum', markdown: 'Markdown', html: 'HTML' }[linkPrefs.embedMode]} code</label>
    </div>
    <div class="field-body">
      <div class="field">
        <div class="control">
          <textarea class="textarea" readonly value={embedCode} rows="3" on:mouseup={smartSelect}></textarea>
        </div>
      </div>
    </div>
  </div>
</fieldset>
