'use strict'

import { apiCall } from './api'

// This is loaded once on startup and contains static app data as well the currently logged-in user - it won't change without reload
export const appInfo = window.appInfo = getAppInfoFromMeta()

function getAppInfoFromMeta () {
  try {
    return Object.assign(JSON.parse(document.querySelector('meta[name="cherryshare:info"]').content), { initialized: true })
  } catch (e) {
    console.warn('Cannot initialize app info from meta tag', e)
  }
  return { initialized: false }
}

export async function initAppInfo () {
  for (const k in appInfo) delete appInfo[k]
  Object.assign(appInfo, await apiCall('GET', '/info'), { initialized: true })
}
