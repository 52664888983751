<script>
  import { onMount, onDestroy } from 'svelte'
  import RouterLink from '@spaceavocado/svelte-router/component/link'
  import LoginLink from './LoginLink.svelte'
  import logo from '../images/logo.svg'
  import { appInfo } from '../lib/appInfo'
  import Gravatar from 'svelte-gravatar'

  let navbarMenu
  let hamburgerIsActive = false

  onMount(() => {
    document.documentElement.classList.add('has-navbar-fixed-top')
    navbarMenu.addEventListener('click', e => {
      hamburgerIsActive = false

      // Make sure the dropdowns won't just stay open after clicking an element on them
      // https://github.com/jgthms/bulma/issues/2514
      const dropdown = e.target.closest('.navbar-dropdown')
      if (dropdown) {
        const dropdownOuter = e.target.closest('.has-dropdown')
        dropdownOuter.classList.remove('is-hoverable')
        dropdown.style.display = 'none'
        setTimeout(() => {
          dropdownOuter.blur()
          dropdownOuter.classList.add('is-hoverable')
          dropdown.style.display = ''
        }, 150)
      }
    })
  })
  onDestroy(() => {
    document.documentElement.classList.remove('has-navbar-fixed-top')
  })

  function toggleMenu () {
    hamburgerIsActive = !hamburgerIsActive
  }
</script>

<nav class="navbar is-fixed-top has-shadow" role="navigation" aria-label="main navigation">
  <div class="container">
    <div class="navbar-brand">
      <RouterLink to={{ name: 'HOME' }} cls="navbar-item">
        <img title="CherryShare" src={logo} width="93" height="28" alt="CherryShare" />
      </RouterLink>

      <a role="button" class="navbar-burger burger" class:is-active={hamburgerIsActive} aria-label="menu" aria-expanded="false" data-target="navbarMenu" href={undefined} on:click={toggleMenu}>
        <span aria-hidden="true" />
        <span aria-hidden="true" />
        <span aria-hidden="true" />
      </a>
    </div>

    <div bind:this={navbarMenu} id="navbarMenu" class="navbar-menu" class:is-active={hamburgerIsActive}>
      <div class="navbar-start">
        <RouterLink to={{ name: 'HOME' }} cls="navbar-item" exact>Upload</RouterLink>

        <RouterLink to={{ name: 'myFiles' }} cls="navbar-item">My Files</RouterLink>

        <div class="navbar-item has-dropdown is-hoverable">
          <a class="navbar-link" href={undefined}>More</a>

          <div class="navbar-dropdown">
            <RouterLink to={{ name: 'rules' }} cls="navbar-item">The Rules</RouterLink>
            <RouterLink to={{ name: 'faq' }} cls="navbar-item">FAQ</RouterLink>
            <hr class="navbar-divider" />
            <RouterLink to={{ name: 'about' }} cls="navbar-item">About CherryShare</RouterLink>
            <RouterLink to={{ name: 'reportAbuse' }} cls="navbar-item">Report Abuse</RouterLink>
          </div>
        </div>
      </div>

      <div class="navbar-end">
        {#if appInfo.userId}
          <div class="navbar-item has-dropdown is-hoverable">
            <a class="navbar-link" href={undefined}>
              <figure class="image mr-2">
                <Gravatar email={appInfo.email || `user${appInfo.userId}@cherryshare.at`} size=28 default="monsterid" rating="pg" class="is-rounded" />
                </figure>
              {appInfo.username}
            </a>

            <div class="navbar-dropdown">
              <RouterLink to={{ name: 'profile' }} cls="navbar-item">Profile</RouterLink>
              <hr class="navbar-divider" />
              <LoginLink logout cls="navbar-item">Log out</LoginLink>
            </div>
          </div>
        {:else}
          <div class="navbar-item">
            <div class="buttons">
              <RouterLink to={{ name: 'signup' }} cls="button is-primary">Sign up</RouterLink>
              <LoginLink cls="button is-light">Log in</LoginLink>
            </div>
          </div>
        {/if}
      </div>
    </div>
  </div>
</nav>
