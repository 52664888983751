<script>
  import 'bulma'
  import './styles/app.scss'

  import { tick, onDestroy } from 'svelte'
  import Nav from './components/Nav.svelte'
  import { RouterView, router, shouldFixRouter } from './router'

  import ShareFlard from './components/ShareFlard.svelte'
  import Footer from './components/Footer.svelte'
  import Dummy from './components/Dummy.svelte'
  import { Toast } from 'svelma'

  import { escape } from './lib/utils'
  import { appInfo, initAppInfo } from './lib/appInfo'

  const shareFlard = location.hash.toLowerCase() === '#shareflard'

  // Dirty workaround for https://github.com/spaceavocado/svelte-router/issues/13
  let routerFix = false
  let unregister = $router.onNavigationChanged((from, to) => {
    if (from && to && from.name === to.name && shouldFixRouter()) {
      console.log('(router fix)')
      routerFix = true
      tick().then(() => (routerFix = false))
    }
  })

  onDestroy(() => {
    unregister()
    unregister = null
  })

  const appInfoPromise = (async () => {
    try {
      if (!appInfo.initialized) await initAppInfo()

      window.onerror = message => {
        if (message === 'ResizeObserver loop limit exceeded') return // Not sure where this error comes from
        Toast.create({ message: `An error occured: ${escape(message)}`, type: 'is-danger' })
      }

      // Note: This doesn't fire for a different script origin, and webpack-internal:/// does count as different script origin... in production hopefully it won't happen
      window.onunhandledrejection = ({ reason }) => {
        Toast.create({ message: `An error occured: ${escape(reason)}`, type: 'is-danger' })
      }

      document.body.classList.add('app-ready')
    } catch (e) {
      setTimeout(() => { throw e }, 0) // We want the global onerror to handle this one too
      throw e
    }
  })()
</script>

{#await appInfoPromise}
  <!-- Loading -->
{:then}
  <Nav />

  <div id="sticky-footer-outer-wrapper">
    <div id="sticky-footer-inner-wrapper">
      {#if shareFlard}
        <ShareFlard />
      {/if}

      <svelte:component this={routerFix ? Dummy : RouterView} />
    </div>

    <Footer />
  </div>
{:catch}
  <!-- onerror will show the error here -->
{/await}
