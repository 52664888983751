<script>
  export let fileId

  import { apiCall } from '../lib/api'
  import ErrorBox from '../components/ErrorBox.svelte'
  import MiddleBox from '../components/MiddleBox.svelte'
  import FileLinks from '../components/FileLinks.svelte'
  import MetadataItem from '../components/MetadataItem.svelte'
  import Loader from '../components/Loader.svelte'
  import UploadZone from '../components/UploadZone.svelte'
  import TextPreview from '../components/TextPreview.svelte'
  import Dummy from '../components/Dummy.svelte'
  import { Icon, Field, Input, Button, Toast, Tooltip, Dialog, Select, Notification, Modal, Switch } from 'svelma'
  import RouterLink from '@spaceavocado/svelte-router/component/link'
  import { onMount, onDestroy, afterUpdate, tick } from 'svelte'
  import { slide } from 'svelte/transition'
  import logo2 from '../images/logo2.svg'
  import Gravatar from 'svelte-gravatar'
  import filesize from 'file-size'
  import { fromS } from 'hh-mm-ss'
  import { appInfo } from '../lib/appInfo'
  import { escape } from '../lib/utils'
  import { router } from '@spaceavocado/svelte-router'
  import { uploadedFiles } from '../stores/uploadedFiles'

  let file = { id: fileId }
  let password = ''
  let pwSuffix = ''
  $: pwSuffix2 = pwSuffix.replace(/^\?/, '&')
  let loading
  let globalLoadingMsg
  let reloading = false

  let reloadInterval
  onMount(() => {
    reloadInterval = setInterval(reload, 600000)
  })

  onDestroy(() => {
    clearInterval(reloadInterval)
  })

  const checkedExtensions = ['png', 'gif', 'jpg', 'svg', 'wav', 'mp3', 'bmp']
  $: badExtension =
    file.current &&
    checkedExtensions.includes(file.current.extension.replace(/^jpeg$/, 'jpg')) &&
    checkedExtensions.includes((file.current.metadata.detectedExtension || '').replace(/^jpeg$/, 'jpg')) &&
    file.current.extension.replace(/^jpeg$/, 'jpg') !== (file.current.metadata.detectedExtension || '').replace(/^jpeg$/, 'jpg')

  let pwFormElement
  let focusPwInput = false

  $: muIndex = ($uploadedFiles).findIndex(f => f.id === fileId)
  let muIndexSelection
  onMount(() => {
    muIndexSelection = String(muIndex)
  })

  // Not using <Collapse> because for some reason it didn't update the selection state of the buttons inside of the slot... (before I had moved it into a component btw)
  // This was not reproducible in REPL https://svelte.dev/repl/30baf9273d6d4489b3f2f55289dbbb17?version=3
  // But I'm more flexible this way anyway
  let linkBoxOpen
  onMount(() => {
    linkBoxOpen = muIndex > -1
  })

  function muGoto (index) {
    $router.push({
      name: 'fileDetails',
      params: {
        id: $uploadedFiles[index].id
      }
    })
  }

  afterUpdate(() => {
    if (focusPwInput && pwFormElement) {
      pwFormElement.querySelector('input').focus()
      focusPwInput = false
    }
  })

  async function load (view = true) {
    loading = true
    try {
      file = await apiCall('GET', `/files/${fileId}`, { view: view ? 1 : undefined, password: password || undefined })
      console.log('File loaded', file)

      if (muIndex > -1) {
        uploadedFiles.update(array => {
          array[muIndex].filename = file.current ? file.current.filename : '(deleted)'
          return array
        })
      }

      if (file.needsPassword) focusPwInput = true
    } finally {
      loading = false
    }
  }

  async function submitPassword () {
    pwSuffix = `?password=${encodeURIComponent(password)}` // Used for download links, etc.
    try {
      await load()
    } catch (e) {
      console.error('Error reloading with password', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'An unknown error occured', type: 'is-danger' })
    }
  }

  let previewBox
  let html5Media
  let html5Duration
  let html5Width
  let html5Height
  let mediaLoading

  function showMediaLoader () {
    if (previewBox.classList.contains('loading')) {
      mediaLoading = true
    }
  }

  function showPreview () {
    mediaLoading = false
    previewBox.classList.remove('loading')

    if (html5Media) {
      html5Duration = Math.round(html5Media.duration)
      html5Width = html5Media.videoWidth
      html5Height = html5Media.videoHeight

      if (html5Duration === Infinity) html5Duration = null // Prevent Infinity:NaN:NaN display
    }
  }

  function downloadClick () {
    if (file.username !== appInfo.username) file.downloads++
  }

  let newStatus = null
  let newPassword = null
  let privacySaving = false
  let newFilename = null
  let filenameSaving = false
  let filenameFormElement
  let newTitle = null
  let titleSaving = false
  let titleFormElement
  let newDescription = null
  let descriptionSaving = false
  let descriptionFormElement

  let newVersionModalActive = false
  let uploadInProgress
  let uploadCompletionInProgress
  let finishedFiles = []
  let keepFilename = false

  function uploadNewVersion () {
    uploadInProgress = false
    uploadCompletionInProgress = false
    finishedFiles = []
    newVersionModalActive = true
  }

  async function continueUpload () {
    try {
      uploadCompletionInProgress = true
      let filename = finishedFiles[0].name
      if (keepFilename) {
        const keptExtension = getExtension(file.current.filename)
        const actualExtension = getExtension(finishedFiles[0].name)

        if (keptExtension !== actualExtension) {
          if (!await Dialog.confirm({
            message: `
              It seems you are changing the extension of the file from <code>${escape(actualExtension) || '(unknown)'}</code> to <code>${escape(keptExtension) || '(unknown)'}</code> (because the original file had the extension <code>${escape(keptExtension) || '(unknown)'}</code> but your new version has <code>${escape(actualExtension) || '(unknown)'}</code>).<br>
              <br>A mismatching extension can cause trouble for users who are downloading the file.<br>
              <br>
              Are you sure this is correct and you want to continue?
            `,
            title: 'Extension mismatch',
            icon: 'exclamation-triangle',
            confirmText: 'Yes',
            cancelText: 'No'
          })) {
            return
          }
        }

        filename = file.current.filename
      }

      file = await apiCall('POST', `/files/${file.id}/version`, { draftFileId: finishedFiles[0].serverFileId, filename })
      Toast.create({ message: 'New version was saved successfully', type: 'is-success' })


      newVersionModalActive = false
      await tick()
    } catch (e) {
      console.error('Error while linking new version', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'Saving new version failed!', type: 'is-danger' })
    } finally {
      uploadCompletionInProgress = false
    }
  }

  async function deleteFile () {
    if (!await Dialog.confirm({
      message: `
        You are about to delete your file "<strong>${escape(file.current.filename)}</strong>" permanently, including all previous versions, if any.<br>
        <br>
        <strong>This action cannot be undone!</strong> If you just want to hide the file, consider changing it to private instead.<br>
        <br>
        Are you sure?
      `,
      title: 'Delete file',
      icon: 'exclamation-triangle',
      confirmText: 'Yes',
      cancelText: 'No'
    })) {
      return
    }

    globalLoadingMsg = 'Deleting...'
    try {
      file = await apiCall('DELETE', `/files/${file.id}`)
      Toast.create({ message: 'File has been deleted', type: 'is-success' })
      $router.push({ name: 'myFiles' })
    } catch (e) {
      console.error('Error while deleting', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'Deleting file failed!', type: 'is-danger' })
      globalLoadingMsg = null
    }
  }

  async function reload () {
    try {
      reloading = true
      await load(false)
      reloading = false
    } catch (e) {
      console.error('Error while reloading', e)
      location.reload()
    }
  }

  async function restoreVersion (version) {
    if (!await Dialog.confirm({
      message: `
        You are about to restore version <strong>${escape(String(version.version))}</strong> of this file ("<strong>${escape(version.filename)}</strong>") as its current main version.<br>
        <br>
        Continue?
      `,
      title: 'Restore version',
      confirmText: 'Yes',
      cancelText: 'No'
    })) {
      return
    }

    globalLoadingMsg = 'Restoring...'
    try {
      file = await apiCall('POST', `/files/${file.id}/version/${version.version}/restore`)
      Toast.create({ message: 'Version has been restored', type: 'is-success' })
    } catch (e) {
      console.error('Error while restoring', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'Restoring version failed!', type: 'is-danger' })
    } finally {
      globalLoadingMsg = null
    }
  }

  async function deleteVersion (version) {
    if (!await Dialog.confirm({
      message: `
        You are about to delete version <strong>${escape(String(version.version))}</strong> of this file ("<strong>${escape(version.filename)}</strong>") permanently.<br>
        <br>
        <strong>This action cannot be undone!</strong><br>
        <br>
        Are you sure?
      `,
      title: 'Delete version',
      icon: 'exclamation-triangle',
      confirmText: 'Yes',
      cancelText: 'No'
    })) {
      return
    }

    globalLoadingMsg = 'Deleting...'
    try {
      file = await apiCall('DELETE', `/files/${file.id}/version/${version.version}`)
      Toast.create({ message: 'Version has been deleted', type: 'is-success' })
    } catch (e) {
      console.error('Error while deleting', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'Deleting version failed!', type: 'is-danger' })
    } finally {
      globalLoadingMsg = null
    }
  }

  function editPrivacy () {
    newStatus = file.status
    newPassword = file.password || ''
  }

  async function submitPrivacy () {
    privacySaving = true
    try {
      file = await apiCall('PATCH', `/files/${file.id}`, { status: newStatus, password: newPassword })
      Toast.create({ message: 'Privacy settings were changed successfully', type: 'is-success' })
      newStatus = null
      newPassword = null
    } catch (e) {
      console.error('Error while saving', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'Saving changes failed!', type: 'is-danger' })
    } finally {
      privacySaving = false
    }
  }

  function cancelPrivacy () {
    newStatus = null
    newPassword = null
  }

  async function changeOwner () {
    const username = await Dialog.prompt({
      message: '<strong>Username:</strong>',
      icon: 'user-edit',
      prompt: file.username,
      title: 'Change owner',
      showCancel: true
    })

    if (username !== null) {
      try {
        file = await apiCall('PATCH', `/files/${file.id}`, { username })
        Toast.create({ message: 'Owner changed', type: 'is-success' })
      } catch (e) {
        console.error('Error changing owner', e)
        Toast.create({ message: escape(e.serverErrorMessage) || 'Failed to change owner!', type: 'is-danger' })
      }
    }
  }

  async function editFilename () {
    newFilename = file.current.filename || ''
    await tick()
    const input = filenameFormElement.querySelector('input')
    input.focus()
    const extension = getExtension(newFilename)
    input.selectionEnd = input.selectionStart = newFilename.length - (extension ? extension.length + 1 : 0)
  }

  function getExtension (filename) {
    return (filename.match(/^\.[^.]+$/) ? '' : filename.split('.').pop() || '').toLowerCase()
  }

  async function fixBadExtension () {
    const prevExtension = getExtension(file.current.filename)
    const correctedFilename = file.current.filename.slice(0, -prevExtension.length) + file.current.metadata.detectedExtension

    filenameSaving = true
    try {
      file = await apiCall('PATCH', `/files/${file.id}`, {
        current: { filename: correctedFilename }
      })
      Toast.create({ message: 'Extension was fixed successfully', type: 'is-success' })
      newFilename = null
    } catch (e) {
      console.error('Error while saving', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'Saving changes failed!', type: 'is-danger' })
    } finally {
      filenameSaving = false
    }
  }

  async function submitFilename () {
    const prevExtension = getExtension(file.current.filename)
    const newExtension = getExtension(newFilename)
    if (prevExtension !== newExtension) {
      if (!await Dialog.confirm({
        message: `
          It seems you are changing the extension of the file from <code>${escape(prevExtension) || '(unknown)'}</code> to <code>${escape(newExtension) || '(unknown)'}</code>.<br>
          <br>
          A mismatching extension can cause trouble for users who are downloading the file.<br>
          <br>
          Are you sure this is correct and you want to continue?
        `,
        title: 'Extension changed',
        icon: 'exclamation-triangle',
        confirmText: 'Yes',
        cancelText: 'No'
      })) {
        return
      }
    }

    filenameSaving = true
    try {
      file = await apiCall('PATCH', `/files/${file.id}`, {
        current: { filename: newFilename }
      })
      Toast.create({ message: 'Filename was changed successfully', type: 'is-success' })
      newFilename = null
    } catch (e) {
      console.error('Error while saving', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'Saving changes failed!', type: 'is-danger' })
    } finally {
      filenameSaving = false
    }
  }

  function cancelFilename () {
    newFilename = null
  }

  async function editTitle () {
    newTitle = file.title || ''
    await tick()
    titleFormElement.querySelector('input').focus()
  }

  async function submitTitle () {
    titleSaving = true
    try {
      file = await apiCall('PATCH', `/files/${file.id}`, { title: newTitle })
      Toast.create({ message: 'Title was changed successfully', type: 'is-success' })
      newTitle = null
    } catch (e) {
      console.error('Error while saving', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'Saving changes failed!', type: 'is-danger' })
    } finally {
      titleSaving = false
    }
  }

  function cancelTitle () {
    newTitle = null
  }

  async function editDescription () {
    newDescription = file.description || ''
    await tick()
    descriptionFormElement.querySelector('textarea').focus()
  }

  async function submitDescription () {
    descriptionSaving = true
    try {
      file = await apiCall('PATCH', `/files/${file.id}`, { description: newDescription })
      Toast.create({ message: 'Description was changed successfully', type: 'is-success' })
      newDescription = null
    } catch (e) {
      console.error('Error while saving', e)
      Toast.create({ message: escape(e.serverErrorMessage) || 'Saving changes failed!', type: 'is-danger' })
    } finally {
      descriptionSaving = false
    }
  }

  function cancelDescription () {
    newDescription = null
  }
</script>

<style lang="scss">/* Buttons require more space */
.clearfix {
  clear: both;
}

.options > span {
  display: inline-block;
  margin-right: 2rem;
  margin-bottom: 1rem;
}

.delete-file-link:hover {
  color: white;
  background: #f79c12;
}

.box.is-fullwidth, .preview-box.is-fullwidth {
  width: 100%;
}

.description {
  white-space: pre-wrap;
}

.preview-box {
  transition: opacity 0.5s;
  opacity: 1;
}
.preview-box.loading {
  opacity: 0;
  pointer-events: none;
}
.preview-box audio {
  width: 100%;
}
.preview-box video {
  max-height: calc(95vh - 120px);
}
.preview-box audio, .preview-box video {
  outline: none;
}
.preview-box .placeholder {
  filter: invert(1);
  opacity: 0.1;
  margin-top: 50px;
  margin-bottom: 50px;
  height: 25vh;
}

.preview {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  align-content: center;
}
.preview iframe {
  width: 100%;
}
.preview .document-preview figure {
  padding-top: calc(min(150%, 100vh - 320px)) !important;
}

.avatar {
  width: 48px;
  height: 48px;
}</style>

<svelte:head>
	<title>{file.current ? file.current.filename + ' - ' : ''}CherryShare</title>
</svelte:head>

{#if $uploadedFiles.length > 1 && muIndex > -1}
  <div class="container">
    <Notification type="is-success" on:close={() => { uploadedFiles.set([]) }}>
      <strong>Your {$uploadedFiles.length} files have been uploaded!</strong>
      <div class="has-text-centered">Viewing file {muIndex + 1} of {$uploadedFiles.length}</div>
      <div class="columns is-mobile">
        <div class="column is-narrow">
          <Button type="is-success" disabled={muIndex <= 0} inverted outlined on:click={() => muGoto(muIndex - 1)} iconLeft="arrow-left">
            <span class="is-hidden-mobile">Previous</span>
          </Button>
        </div>
        <div class="column">
          <Select expanded bind:selected={muIndexSelection} on:input={() => muGoto(Number(muIndexSelection))}>
            {#each $uploadedFiles as { filename }, index}
              <option value={String(index)}>{Number(index) + 1}: {filename}</option>
            {/each}
          </Select>
        </div>
        <div class="column is-narrow">
          <Button type="is-success" disabled={muIndex >= $uploadedFiles.length - 1} inverted outlined on:click={() => muGoto(muIndex + 1)} iconRight="arrow-right">
            <span class="is-hidden-mobile">Next</span>
          </Button>
        </div>
      </div>
    </Notification>
  </div>
{/if}

<div class="container p-3">
  {#await load()}
    <div class="is-flex is-justify-content-center is-align-items-center p-6">
      <Icon icon="spinner" size="is-large" customClass="fa-pulse" />
    </div>
  {:then}
    {#if globalLoadingMsg}

      <Loader text={globalLoadingMsg} />

    {:else if file.needsPassword}

      <MiddleBox class="has-text-centered">
        <p class="m-3"><Icon icon="lock" size="is-large" /></p>
        <h1 class="title">This file is protected</h1>
        <form on:submit|preventDefault={submitPassword} bind:this={pwFormElement}>
          <fieldset disabled={loading}>
            <Field label="Enter password:" type={(pwSuffix && !loading) ? 'is-danger' : undefined} message={(pwSuffix && !loading) ? 'Incorrect password' : undefined}>
              <Input type="password" bind:value={password} icon="star-of-life" class={(pwSuffix && !loading) ? 'is-danger' : ''} />
            </Field>

            <Field>
              <Button type="is-primary" nativeType="submit" {loading}>Submit</Button>
            </Field>
          </fieldset>
        </form>
      </MiddleBox>

    {:else if file.status === 'deleted'}

      <MiddleBox class="has-text-centered">
        <p class="m-3"><Icon icon="trash" size="is-large" /></p>
        <h1 class="title">This file has been deleted</h1>
      </MiddleBox>

    {:else if file.status === 'draft'}

      <MiddleBox class="has-text-centered">
        <p class="m-3"><Icon icon="frown" size="is-large" /></p>
        <h1 class="title">This file is not ready yet</h1>
      </MiddleBox>

    {:else if file.status === 'private' && !file.fullAccess}

      <MiddleBox class="has-text-centered">
        <p class="m-3"><Icon icon="user-lock" size="is-large" /></p>
        <h1 class="title">This file is private</h1>
      </MiddleBox>

    {:else}

      <section class="columns mt-6">
        <div class="column">
          <h2 class="title is-2">
            {#if newFilename === null}
              {file.current.filename}
              {#if file.fullAccess}
                <a href={undefined} on:click={editFilename}><Tooltip type="is-dark" label="Edit filename"><Icon icon="pen" /></Tooltip></a>
              {/if}
            {:else}
              <form on:submit|preventDefault={submitFilename} bind:this={filenameFormElement}>
                <fieldset disabled={filenameSaving}>
                  <Field>
                    <Input type="text" placeholder="Enter filename" expanded bind:value={newFilename} />
                    <p class="control">
                      <Button nativeType="submit" type="is-success" disabled={!newFilename.trim()} loading={filenameSaving}><Icon icon="check" /></Button>
                    </p>
                    <p class="control">
                      <Button type="is-danger" on:click={cancelFilename} disabled={filenameSaving}><Icon icon="times" /></Button>
                    </p>
                  </Field>
                </fieldset>
              </form>
            {/if}
          </h2>
          {#if file.title || file.fullAccess}
            <h3 class="subtitle is-4">
              {#if newTitle === null}
                {#if file.title}
                  <em>{file.title}</em>
                {:else}
                  <em class="has-text-grey-light">No title</em>
                {/if}
                {#if file.fullAccess}
                  <a href={undefined} on:click={editTitle}><Tooltip type="is-dark" label="Edit title"><Icon icon="pen" /></Tooltip></a>
                {/if}
              {:else}
                <form on:submit|preventDefault={submitTitle} bind:this={titleFormElement}>
                  <fieldset disabled={titleSaving}>
                    <Field>
                      <Input type="text" maxlength="200" placeholder="No title" expanded bind:value={newTitle} />
                      <p class="control">
                        <Button nativeType="submit" type="is-success" loading={titleSaving}><Icon icon="check" /></Button>
                      </p>
                      <p class="control">
                        <Button type="is-danger" on:click={cancelTitle} disabled={titleSaving}><Icon icon="times" /></Button>
                      </p>
                    </Field>
                  </fieldset>
                </form>
              {/if}
            </h3>
          {/if}

          {#if badExtension && file.fullAccess}
            <Notification type="is-warning">
              Your filename has the extension "{file.current.extension}" but it seems that the file is actually a "{file.current.metadata.detectedExtension}" file. This can cause trouble for the users downloading the file.
              <br />
              <br />
              <Button type="is-warning" inverted outlined on:click={fixBadExtension} loading={filenameSaving}>Fix now</Button>
            </Notification>
          {/if}

          {#if file.oldFolderName && file.fullAccess}
            <h6 class="subtitle is-6">
              <Icon icon="folder" /> {file.oldFolderName}
            </h6>
          {/if}

          <div class="columns">
            <div class="options column">
              <span><a href={undefined} on:click={() => { linkBoxOpen = !linkBoxOpen }}><Icon icon="link" /> Link{file.current.metadata.type === 'image' ? '/embed' : ''}</a></span>
              {#if !file.fullAccess}
                <span><RouterLink to={{ name: 'reportAbuse', query: { fileId } }}><Icon icon="flag" /> Report abuse</RouterLink></span>
              {:else}
                <span><a href={undefined} on:click={uploadNewVersion}><Icon icon="upload" /> Upload new version</a></span>
                <span><a href={undefined} on:click={deleteFile} class="delete-file-link"><Icon icon="trash" /> Delete file</a></span>
              {/if}
            </div>

            <div class="column is-narrow">
              <span>
                {#if reloading}
                  <Icon icon="spinner" customClass="fa-pulse" />
                {:else}
                  <a href={undefined} on:click={reload}><Icon icon="sync" /> Refresh</a>
                {/if}
              </span>
            </div>
          </div>

          {#if linkBoxOpen}
            <div class="box" transition:slide>
              <FileLinks {file} />
            </div>
          {/if}

          {#if file.description || file.fullAccess}
            <div class="box">
              {#if newDescription === null}
                {#if file.description}
                  <span class="description">{file.description}</span>
                {:else}
                  <span class="description has-text-grey-light">No description</span>
                {/if}
                {#if file.fullAccess}
                  <a href={undefined} on:click={editDescription}><Tooltip type="is-dark" label="Edit description"><Icon icon="pen" /></Tooltip></a>
                {/if}
              {:else}
                <form on:submit|preventDefault={submitDescription} bind:this={descriptionFormElement}>
                  <fieldset disabled={descriptionSaving}>
                    <Field>
                      <Input type="textarea" maxlength="5000" placeholder="No description" expanded bind:value={newDescription} />
                    </Field>
                    <Field>
                      <div class="buttons clearfix is-right">
                        <Button nativeType="submit" type="is-success" loading={descriptionSaving} iconLeft="check">Save</Button>
                        <Button type="is-danger" on:click={cancelDescription} disabled={descriptionSaving} iconLeft="times">Cancel</Button>
                      </div>
                    </Field>
                  </fieldset>
                </form>
              {/if}
            </div>
          {/if}

          {#if mediaLoading}
            <div class="is-flex is-justify-content-center is-align-items-center p-6">
              <Icon icon="spinner" size="is-large" customClass="fa-pulse" />
            </div>
          {/if}

          {#if file.current.extension === 'ips'}
            <section class="preview my-3 has-text-left">
              <div class="box preview-box">
                <Icon icon="lightbulb" />
                This is a patch in IPS format. You can use a tool like <a href="https://www.romhacking.net/utilities/240/" target="_blank" rel="noopener">Lunar IPS</a> to apply the patch. After selecting the patch file, you may have to select "All files" to see the desired target file in the list. Remember to always create a backup of the files that you are modifying!
              </div>
            </section>
          {:else if file.current.extension === 'hpd' || file.current.extension === 'hpa'}
            <section class="preview my-3 has-text-left">
              <div class="box preview-box">
                <Icon icon="lightbulb" />
                This is a patch in the Hyper Patcher 2 format. You can download Hyper Patcher 2 <a href="http://cherrytree.at/cms/download/2011/12/17/dl-10-hyper-patcher-2-demo.html" target="_blank" rel="noopener">here</a>. It's only available in German, but it's simple to use for patching nonetheless: Open it, select the project folder, go to tab "Sonstiges 3" and click "Patch anwenden". Afterwards you have to select the patch file and select a patch from the file (a file can have multiple patches). Remember to always create a backup of the files that you are modifying!
              </div>
            </section>
          {:else if file.current.extension === 'rar' || file.current.extension === '7z' || file.current.extension === 'lzh'}
            <section class="preview my-3 has-text-left">
              <div class="box preview-box">
                <Icon icon="lightbulb" />
                If you have trouble opening this file, try <a href="https://www.7-zip.org/download.html" target="_blank" rel="noopener">7-Zip</a>.
              </div>
            </section>
          {/if}

          {#if file.hasPreview}
            {#if file.current.metadata.type === 'image'}
              <section class="preview my-3">
                <div class="box preview-box loading" bind:this={previewBox}>
                  <a href="/files/{file.id}/{file.current.version}/inline/{encodeURIComponent(file.current.filename)}{pwSuffix}" target="_blank" rel="noopener" on:click={downloadClick}>
                    <figure class="image">
                      <img src="/files/{file.id}/{file.current.version}/preview{pwSuffix2}" alt="Preview" on:load={showPreview} />
                    </figure>
                  </a>
                </div>
              </section>
            {:else if file.current.metadata.type === 'audio'}
              <section class="preview my-3">
                <div class="box preview-box is-fullwidth loading" bind:this={previewBox}>
                  <p class="my-5"><Icon icon="music" size="is-large" /></p>
                  <audio bind:this={html5Media} src="/files/{file.id}/{file.current.version}/preview{pwSuffix2}" alt="Preview" preload="metadata" on:progress={showMediaLoader} on:loadedmetadata={showPreview} controls controlsList="nodownload">
                    <track kind="captions" />
                  </audio>
                </div>
              </section>
              {:else if file.current.metadata.type === 'video'}
              <section class="preview my-3">
                <div class="box preview-box loading" bind:this={previewBox}>
                  <figure class="image">
                    <video bind:this={html5Media} src="/files/{file.id}/{file.current.version}/preview{pwSuffix2}#t=0.1" alt="Preview" preload="metadata" on:progress={showMediaLoader} on:loadedmetadata={showPreview} controls controlsList="nodownload">
                      <track kind="captions" />
                    </video>
                  </figure>
                </div>
              </section>
            {:else if file.current.mimeType.startsWith('text/') || ['application/json', 'application/xml'].includes(file.current.mimeType)}
              <section class="preview my-3">
                <div class="box preview-box loading" bind:this={previewBox}>
                  <TextPreview on:load={showPreview} src="/files/{file.id}/{file.current.version}/preview{pwSuffix2}" />
                </div>
              </section>
            {:else if ['pdf', 'odt', 'ods', 'odp'].includes(file.current.metadata.detectedExtension)}
              <section class="preview my-3">
                <div class="box preview-box is-fullwidth loading document-preview" bind:this={previewBox}>
                  <figure class="image is-2by3">
                    <iframe src="/ViewerJS/index.html?zoom=page-width#/files/{file.id}/{file.current.version}/preview{pwSuffix2}" on:load={showPreview} title="Document preview" class="has-ratio" width="600" height="800"></iframe>
                  </figure>
                </div>
              </section>
              <Dummy on:render={showMediaLoader} />
            {/if}
          {:else}
            <section class="preview my-3 is-hidden-mobile">
              <!-- This one is no real box -->
              <div class="preview-box loading" bind:this={previewBox}>
                <figure class="image">
                  <img src={logo2} class="placeholder" alt="CherryShare" on:load={showPreview} />
                </figure>
              </div>
            </section>
          {/if}
        </div>

        <div class="column is-one-quarter has-text-centered">
          <Button
            size="is-large"
            type="is-primary"
            tag="a"
            href="/files/{file.id}/{file.current.version}/download/{encodeURIComponent(file.current.filename)}{pwSuffix}"
            iconLeft="download"
            class="is-fullwidth"
            rounded
            on:click={downloadClick}
          >
            Download
          </Button>

          <a href="/files/{file.id}/{file.current.version}/inline/{encodeURIComponent(file.current.filename)}{pwSuffix}" target="_blank" rel="noopener" class="my-2 is-inline-block" on:click={downloadClick}>Open</a>

          <aside class="my-5">
            <div class="uploader">
              <div class="columns has-text-left is-mobile">
                <div class="column is-narrow has-text-centered">
                  <figure class="image avatar">
                    <Gravatar md5={file.avatarHash} size=48 default="monsterid" rating="pg" class="is-rounded" />
                  </figure>
                </div>
                <div class="column">
                  Uploaded by<br />
                  <strong>{file.username}</strong>
                  {#if appInfo.admin}
                    <a href={undefined} on:click={changeOwner}><Tooltip type="is-dark" label="Change owner"><Icon icon="pen" /></Tooltip></a>
                  {/if}
                  {#if file.fullAccess}
                    <br />
                    <br />
                    {#if newStatus === null}
                      {#if file.status === 'public'}
                        <Icon icon="globe-americas" /> Public
                      {:else if file.status === 'protected'}
                        <Icon icon="lock" /> Protected
                      {:else if file.status === 'private'}
                        <Icon icon="user-lock" /> Private
                      {/if}
                      <a href={undefined} on:click={editPrivacy}><Tooltip type="is-dark" label="Edit privacy"><Icon icon="pen" /></Tooltip></a>
                    {:else}
                      <form on:submit|preventDefault={submitPrivacy}>
                        <fieldset disabled={privacySaving}>
                          <Field>
                            <Select expanded bind:selected={newStatus} iconPack="fa" icon={{ public: 'globe-americas', protected: 'lock', private: 'user-lock' }[newStatus] || 'globe'}>
                              <option value="public">Public</option>
                              <option value="protected">Protected</option>
                              <option value="private">Private</option>
                            </Select>
                          </Field>
                          {#if newStatus === 'protected'}
                            <Field>
                              <Input type="password" icon="star-of-life" placeholder="Password" expanded bind:value={newPassword} passwordReveal={true} />
                            </Field>
                          {/if}
                          <Field>
                            <div class="buttons clearfix is-right">
                              <Button nativeType="submit" type="is-success" loading={privacySaving} iconLeft="check" disabled={newStatus === 'protected' && !newPassword}>Save</Button>
                              <Button type="is-danger" on:click={cancelPrivacy} disabled={privacySaving} iconLeft="times">Cancel</Button>
                            </div>
                          </Field>
                        </fieldset>
                      </form>
                    {/if}
                  {/if}
                </div>
              </div>
            </div>
            <div class="metadata">
              <hr />
              <MetadataItem label="Upload date">{new Date(file.current.uploadDate).toLocaleString()}</MetadataItem>
              {#if file.current.uploadDate !== file.originalUploadDate}
                <MetadataItem label="Original upload date">{new Date(file.originalUploadDate).toLocaleString()}</MetadataItem>
              {/if}
              <MetadataItem label="Views">{file.views.toLocaleString()}</MetadataItem>
              <MetadataItem label="Downloads">{file.downloads.toLocaleString()}</MetadataItem>
              <MetadataItem label="File size">{filesize(file.current.size).human()}</MetadataItem>
              <MetadataItem label="File type">{(file.current.extension || '').toUpperCase() || 'unknown'}</MetadataItem>
              {#if file.current.metadata.type === 'image' && file.current.metadata.width && file.current.metadata.height}
                <MetadataItem label="Dimensions">{file.current.metadata.width} &times; {file.current.metadata.height} px</MetadataItem>
              {/if}
              {#if file.current.metadata.type === 'video'}
                <MetadataItem label="Dimensions">
                  {#if html5Width}
                    {html5Width} &times; {html5Height} px
                  {/if}
                </MetadataItem>
              {/if}
              {#if file.current.metadata.type === 'audio' || file.current.metadata.type === 'video'}
                <MetadataItem label="Duration">{html5Duration ? fromS(html5Duration) : ''}</MetadataItem>
              {/if}
              {#if file.fullAccess}
                <hr />
                <MetadataItem label="Version">{file.current.version}</MetadataItem>
                <MetadataItem label="Last view">{file.lastView ? new Date(file.lastView).toLocaleString() : 'never'}</MetadataItem>
                <MetadataItem label="Last download">{file.lastDownload ? new Date(file.lastDownload).toLocaleString() : 'never'}</MetadataItem>
              {/if}
            </div>
          </aside>
        </div>
      </section>

      {#if file.fullAccess && file.archive.length}
        <hr />
        <h4 class="title is-4">Previous versions</h4>
        <table class="table is-fullwidth">
          <thead>
            <tr>
              <th>Version</th>
              <th>Filename</th>
              <th>Size</th>
              <th>Date</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {#each file.archive as version (version.version)}
              <tr>
                <td><strong>{version.version}</strong></td>
                <td>{version.filename}</td>
                <td>{filesize(version.size).human()}</td>
                <td>{new Date(version.uploadDate).toLocaleString()}</td>
                <td>
                  <Tooltip type="is-dark" label="Download">
                    <Button
                      size="is-small"
                      outlined
                      type="is-primary"
                      tag="a"
                      href="/files/{file.id}/{version.version}/download/{encodeURIComponent(version.filename)}{pwSuffix2}"
                    >
                      <Icon icon="download" />
                    </Button>
                  </Tooltip>
                  <Tooltip type="is-dark" label="Restore">
                    <Button size="is-small" outlined type="is-primary"><Icon icon="undo" on:click={() => restoreVersion(version)} /></Button>
                  </Tooltip>
                  <Tooltip type="is-dark" label="Delete">
                    <Button size="is-small" outlined type="is-danger"><Icon icon="trash" on:click={() => deleteVersion(version)} /></Button>
                  </Tooltip>
                </td>
              </tr>
            {/each}
          </tbody>
        </table>
        <p>Only you can access previous versions. Old versions are deleted after 90 days.</p>
      {/if}

    {/if}

    {#if newVersionModalActive}
      <Modal bind:active={newVersionModalActive}>
        <div class="box">
          <h4 class="title is-4">Upload new version</h4>
          <UploadZone originalFile={file} bind:uploadInProgress bind:finishedFiles disabled={uploadCompletionInProgress} removeFilesOnUnmount={!uploadCompletionInProgress} />
          <div class="level mt-5">
            <div class="level-left">
              <Switch bind:checked={keepFilename} type="is-success">Keep previous filename</Switch>
            </div>
            <div class="level-right">
              <Button type="is-primary" disabled={!finishedFiles.length && !uploadInProgress} loading={uploadInProgress || uploadCompletionInProgress} on:click={continueUpload}>Continue</Button>
            </div>
          </div>
        </div>
      </Modal>
    {/if}

  {:catch error}
    <ErrorBox text="Something went wrong!">{error}</ErrorBox>
  {/await}
</div>
