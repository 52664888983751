<script>
  export let backTo

  import Loader from '../components/Loader.svelte'
  import { apiCall } from '../lib/api'
  import { onMount } from 'svelte'

  onMount(async () => {
    try {
      await apiCall('POST', '/logout')
    } finally {
      location.replace(backTo || '/')
    }
  })
</script>

<svelte:head>
  <title>Logout - CherryShare</title>
</svelte:head>

<div class="container">
  <Loader text="Logging you out..." />
</div>
