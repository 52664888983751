<script>
  import { apiCall } from '../lib/api'
  import { appInfo } from '../lib/appInfo'
  import { Toast, Notification, Tag, Button } from 'svelma'
  import { router } from '@spaceavocado/svelte-router'
  import { uploadedFiles } from '../stores/uploadedFiles'
  import { onMount } from 'svelte'

  export let files = []

  async function load () {
    try {
      files = await apiCall('GET', '/files/drafts')
    } catch (e) {
      console.error('Failed to check for drafts', e)
      Toast.create({ message: `Failed to check for drafts: ${e.serverErrorMessage || 'Unknown error'}`, type: 'is-danger' })
    }
  }

  async function discardFile (file) {
    files = files.filter(f => f.id !== file.id)
    try {
      console.log('Removed file', file)
      await apiCall('DELETE', `/files/${file.id}`)
    } catch (e) {
      console.error('Error deleting unfinished upload', e, file)
      Toast.create({ message: `File ${file.filename} could not be deleted from server: ${e.serverErrorMessage || 'Unknown error'}`, type: 'is-danger' })
    }
  }

  let discarding = false
  let saving = false

  async function discardAll () {
    discarding = true
    try {
      await apiCall('POST', '/uploads/_byFileIds/discard', { fileIds: files.map(f => f.id) })
      files = []
      Toast.create({ message: 'The uploads were discarded successfully', type: 'is-success' })
    } catch (e) {
      console.error('Error discarding uploads', e)
      Toast.create({ message: `There was an error while discarding the uploads: ${e.serverErrorMessage || 'Unknown error'}`, type: 'is-danger' })
    } finally {
      discarding = false
    }
  }

  async function saveAll () {
    saving = true
    try {
      await apiCall('POST', '/uploads/_byFileIds/complete', { fileIds: files.map(f => f.id) })
      const originalFiles = files
      files = []
      Toast.create({ message: 'The uploads were saved successfully', type: 'is-success' })

      uploadedFiles.set(originalFiles)

      $router.push({
        name: 'fileDetails',
        params: {
          id: originalFiles[0].id
        }
      })
    } catch (e) {
      console.error('Error saving uploads', e)
      Toast.create({ message: `There was an error while saving the uploads: ${e.serverErrorMessage || 'Unknown error'}`, type: 'is-danger' })
    } finally {
      saving = false
    }
  }

  // Decouple this check from the rest of the page load
  onMount(() => {
    if (appInfo.userId) load()
  })
</script>

<style lang="scss">.draft-recovery > :global(.notification) {
  margin: 0;
}

.draft-recovery :global(.tag) {
  margin-right: 1rem;
}</style>

{#if files.length}
  <span class="draft-recovery">
    <Notification type="is-info" on:close={discardAll}>
      <div class="container">
        <p>
          <strong>You have unsaved uploads!</strong> Maybe you previously initiated an upload and then closed the window or went to another page. No worries, your files are safe. You can select which files to keep and save them now.
        </p>
        <p class="my-5">
          <strong>Files:</strong>
          {#each files as file (file.id)}
            <Tag closable on:close={() => discardFile(file)}>{file.filename}</Tag>
          {/each}
        </p>
        <div class="level is-mobile">
          <div class="level-left">
            <div class="level-item">
              <Button type="is-info" inverted on:click={saveAll} loading={saving} disabled={discarding}>Save files</Button>
            </div>
          </div>
          <div class="level-right">
            <div class="level-item">
              <Button type="is-info" inverted outlined on:click={discardAll} loading={discarding} disabled={saving}>Discard all</Button>
            </div>
          </div>
        </div>
      </div>
    </Notification>
  </span>
{/if}
