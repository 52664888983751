<script>
  import RouterLink from '@spaceavocado/svelte-router/component/link'
</script>

<svelte:head>
  <title>About - CherryShare</title>
</svelte:head>

<div class="container py-3">
  <div class="content">
    <h3>About CherryShare</h3>
    CherryShare is a personal, non-commercial website that allows members of the RPG Maker community to host images and files for use in forums and similar places.<br />
    <br />
    Webmaster contact address is David Trapp, Peter-Behrens-Platz 10, 4020 Linz, Austria. E-mail address is webmaster{'@'}cherrytree.at. (There is also a business registered at this same address, since I'm also active as sole proprietor in IT services, however this website is not connected to or run under the business entity. I am acting as individual only.)<br />
    <br />
    Most of the content on CherryShare is generated by users. I cannot review everything that is uploaded to this website, and I cannot take responsibility for it. However, if I notice or get informed of anything illegal or otherwise problematic (for example through the <RouterLink to={{ name: 'reportAbuse' }}>reporting form</RouterLink>), I will of course take immediate action and remove the offending content if the claim is valid.<br />
    <br />
    This website doesn't use any tools or components that send data to third parties (such as analytics tools, social logins or advertisements). Cookies are required for logging into a CherryShare account to store the session ID, and local storage is optionally used to save preferences of the embedding code format when the "link/embed" option is used. But other than that, there is no unnecessary data stored or transmitted anywhere. The only external resource is Gravatar, and that's just an image and no script. CherryShare stores the IP addresses of the users who upload content as part of the content itself, together with the date the content was created. Additionally, the date and IP address used for the last login of an account is stored. Users who use CherryShare without an account to only download other users' files will have their IP address together with time and date and referrer only temporarily stored in the server log, which is emptied on a regular basis. To open an account, it is required to truthfully answer the question about the community nickname, but no further identifying information is collected or required. Note that in the unlikely event that I would be contacted by a law enforcement agency, I would be required to render to them any user-identifying information that I have, and I would fully comply with their requests to the extent of the applicable law. Also note that all files, even files set to "private", are visible to me and may be reviewed to verify compliance with the rules.<br />
    <br />
    CherryShare is hosted on infrastructure owned by dogado GmbH in Germany.
  </div>
</div>
