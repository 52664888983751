<script>
  export let backTo

  import { Toast, Button, Field, Input, Dialog } from 'svelma'
  import ErrorBox from '../components/ErrorBox.svelte'
  import MiddleBox from '../components/MiddleBox.svelte'
  import RouterLink from '@spaceavocado/svelte-router/component/link'
  import { apiCall } from '../lib/api'
  import { onMount } from 'svelte'
  import { escape } from '../lib/utils'

  let username = ''
  let password = ''

  let loading = false
  let invalid = false

  let formElement

  onMount(() => {
    if (formElement) formElement.querySelector('input').focus() // Because autofocus doesn't properly work on <Input>
  })

  async function login () {
    loading = true
    invalid = false
    try {
      const { userId } = await apiCall('POST', '/login', { username, password })
      console.log('Login successful', userId)
      location.replace(backTo || '/')
    } catch (e) {
      Toast.create({ message: escape(e.serverErrorMessage) || 'Login failed, please try again', type: 'is-danger' })
      if (e.code === 'invalid_credentials') invalid = true
      if (e.code === 'already_authenticated') location.reload()
      loading = false
    }
  }

  function forgotPassword () {
    Dialog.alert(`
      <strong>Forgot your password? No worries!</strong><br />
      <br />
      There is currently no automatic password recovery feature, but I'm here to help. Just contact me (for example via email on <tt>webmaster@cherrytree.at</tt>) and we'll get this sorted!
    `)
  }
</script>

<svelte:head>
  <title>Login - CherryShare</title>
</svelte:head>

<!-- Note: The is-danger class shouldn't be necessary on the <Input> as per Svelma docs, but it actually is due to a bug: https://github.com/c0bra/svelma/issues/56 -->

<div class="container">
  {#if !navigator.cookieEnabled}
    <ErrorBox text="Sorry, cookies need to be enabled to log in to your CherryShare account!">
      It's possible to view other people's files without cookies enabled, but in order to log in, cookies must be enabled so that the login session can be stored.<br />
      <br />
      Please enable cookies and then <a href={undefined} on:click={() => location.reload()}>reload the page</a>.
    </ErrorBox>
  {:else}
    <MiddleBox>
      <form on:submit|preventDefault={login} bind:this={formElement}>
        <fieldset disabled={loading}>
          <Field label="Username/Email" type={invalid ? 'is-danger' : undefined}>
            <Input type="text" bind:value={username} icon={username.includes('@') ? 'at' : 'user'} class={invalid ? 'is-danger' : ''} />
          </Field>

          <Field label="Password" type={invalid ? 'is-danger' : undefined} message={invalid ? 'Incorrect username or password' : undefined}>
            <Input type="password" bind:value={password} icon="star-of-life" class={invalid ? 'is-danger' : ''} />
          </Field>

          <Field class="is-flex is-justify-content-space-between is-align-items-center">
            <Button type="is-primary" nativeType="submit" {loading}>Log in</Button>
            <div>
              <a href={undefined} on:click={forgotPassword}>Forgot password?</a> &bull;
              <RouterLink to={{ name: 'signup' }} cls="has-text-weight-bold">Sign up</RouterLink>
            </div>
          </Field>
        </fieldset>
      </form>
    </MiddleBox>
  {/if}
</div>
