<script>
  import { appInfo } from '../lib/appInfo'
  import RouterLink from '@spaceavocado/svelte-router/component/link'
  import LoginLink from '../components/LoginLink.svelte'
  import LoneHero from '../components/LoneHero.svelte'
  import DraftRecovery from '../components/DraftRecovery.svelte'
  import UploadZone from '../components/UploadZone.svelte'
  import logo2 from '../images/logo2.svg'
  import { Button } from 'svelma'
  import { apiCall } from '../lib/api'
  import { router } from '@spaceavocado/svelte-router'
  import { uploadedFiles } from '../stores/uploadedFiles'

  let multiUploadId
  let uploadInProgress = false
  let muCompletionInProgress = false
  let finishedFiles = []

  async function continueUpload () {
    try {
      muCompletionInProgress = true

      await apiCall('POST', `/uploads/${multiUploadId}/complete`)

      uploadedFiles.set(finishedFiles.map(file => ({ id: file.serverFileId, filename: file.name })))

      $router.push({
        name: 'fileDetails',
        params: {
          id: finishedFiles[0].serverFileId
        }
      })
    } catch (e) {
      muCompletionInProgress = false
      throw e
    }
  }
</script>

<svelte:head>
  <title>CherryShare</title>
</svelte:head>

<style lang="scss">.side-logo {
  max-height: 225px;
}

.has-links :global(a) {
  color: white;
}

@media screen and (min-width: 769px) {
  .hero-body {
    padding: 100px 1.5rem !important;
  }
  .hero-body :global(.dropzone) {
    min-height: 390px;
  }

  .welcome {
    margin: 3rem -0.75rem;
  }
}</style>

<DraftRecovery />

<LoneHero class="is-primary is-medium">
  <div class="hero-body">
    <div class="container">
      {#if appInfo.userId}
        <UploadZone bind:multiUploadId bind:uploadInProgress bind:finishedFiles disabled={muCompletionInProgress} />
        <div class="is-flex is-justify-content-center is-align-items-center py-5">
          <Button type="is-primary" inverted disabled={!finishedFiles.length && !uploadInProgress} loading={uploadInProgress || muCompletionInProgress} on:click={continueUpload}>Continue</Button>
        </div>
        <p class="has-links has-text-centered">
          <RouterLink to={{ name: 'rules' }}>Rules</RouterLink> - <RouterLink to={{ name: 'faq' }}>FAQ</RouterLink>
        </p>
      {:else}
        <div class="columns welcome">
          <div class="column">
            <h1 class="title">
              File and image hosting for RM friends
            </h1>
            <h2 class="subtitle">
              Hello dear RPG Maker user, welcome to CherryShare, the place to upload your self-made games, screenshots, forum avatars, etc. - run by the guy you know, who doesn't sell your data, randomly delete your files or bombard you with ads - just like in the old times.<br />
              <br />
              You will <strong>need an account</strong> (free, of course) to upload files here. And you should be part of the community, know who I am and follow the <RouterLink to={{ name: 'rules' }}>rules</RouterLink>. That's it - enjoy!
              <br />
              <br />
              <div class="is-flex is-justify-content-flex-start is-align-items-center">
                <LoginLink cls="button is-primary is-medium is-inverted mr-3">Log in</LoginLink>
                <RouterLink to={{ name: 'signup' }}>Sign up</RouterLink>
              </div>
            </h2>
          </div>
          <div class="column is-one-fifth is-hidden-mobile">
            <figure class="image m-4">
              <img class="side-logo" src={logo2} alt="CherryShare" />
            </figure>
          </div>
        </div>
      {/if}
    </div>
  </div>
</LoneHero>
