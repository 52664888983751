<script>
  import { appInfo } from '../lib/appInfo'
</script>

<svelte:head>
  <title>The rules - CherryShare</title>
</svelte:head>

<div class="container py-3">
  <div class="content">
    <h3>Rules</h3>
    In general, the thing is that CherryShare was and still is a hobby project of mine. I pay the server cost because I want to, I don't earn anything from it. It is still alive thanks to you guys, because there is a certain userbase which still uses the service even in the 2020's. But at the end of the day, it's like a shared apartment, you need to take care of it a bit to keep it in good shape.<br />
    <br />
    <strong>tl;dr: Just don't get me or this site into trouble, and keep it "in the family", then everyone will stay happy and can benefit from this site.</strong><br />
    <br />
    <ol>
      <li>You must be a member of the RPG Maker community, and you should have recognized my (nick)name. You need to create an account with CherryShare in which you configure your nickname that you use in one of the listed communities. Be truthful about your Internet identity there - I don't ask for your real name, but the nickname has to be correct.</li>
      <li>Don't advertise CherryShare outside of the community too much, keep it cozy here. It's not a secret that it exists, and of course you can use it to send links to your friends who never heard of the RPG Maker before, but it should be clear that it's not intended to be the next RapidShare (anyone remember that?)... I just want to keep a low profile.</li>
      <li>Don't upload illegal stuff. If you used CherryShare in the past, the way you used it was probably fine - but still, be mindful about what you upload and share especially if you share links outside of the community. There is a reporting form, and I will take reported things down if I feel they could jeopardize me.</li>
      <li>Don't upload porn, please, at least not on the public setting. (Ecchi is fine.)</li>
      <li>Be reasonable about the amount of storage and traffic you use. There is no strict quota, the only limitation is that a single file cannot have more than {appInfo.regularMaxFileSizeMb} MiB (at least by default - this may be changed for specific users), but that doesn't mean you should use it as a free substitute for Dropbox Premium. Also I'm not a CDN - it's fine to host images on CherryShare and post them online in forums, IRC, Twitter, etc. or even your own mini-website if it's on a platform where you can't easily host your images, but don't use it as free CDN for your own webserver, if you have one, to save traffic, that's just not fair.</li>
      <li>Don't hack anything, obviously. If you would be interested in penetration-testing the system, ask me first.</li>
    </ol>
    <br />
    That being said, enjoy your time here :-)<br />
    <br />
    ~Cherry
  </div>
</div>
