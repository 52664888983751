<script>
  export let icon = 'frown'
  export let text = 'An error occured'

  import MiddleBox from './MiddleBox.svelte'
  import { Icon } from 'svelma'
</script>

<MiddleBox class="has-text-centered notification is-danger">
  <p class="m-3"><Icon {icon} size="is-large" /></p>
  <h1 class="title">{text}</h1>
  <h2 class="subtitle"><slot /></h2>
</MiddleBox>
