'use strict'

import ExtendableError from 'es6-error'
import { Toast } from 'svelma'
import { appInfo } from './appInfo'
import { removeUndefined } from './utils'

const API_BASE_URL = '/api'

export class StatusCodeError extends ExtendableError {
  constructor (response, responseBody = null) {
    const msg = (responseBody && responseBody.error) || JSON.stringify(responseBody)
    super(`Request returned status code ${response.status} (${response.statusText})` + (msg ? `, error message: ${msg}` : ''))
    this.response = response
    this.serverErrorMessage = msg || response.statusText
    this.responseBody = responseBody
    this.code = responseBody && responseBody.code
  }
}

export async function apiCall (method, path, data = {}) {
  if (!path.startsWith('/')) throw new Error('Bad path')

  const hasBody = method !== 'GET'
  let query = ''
  if (!hasBody && Object.keys(data).length) {
    const params = new URLSearchParams()
    for (const [k, v] of Object.entries(data)) {
      if (v !== undefined) params.set(k, v)
    }
    const str = params.toString()
    if (str) query = (path.includes('?') ? '&' : '?') + str
  }

  const response = await fetch(API_BASE_URL + path + query, {
    method,
    mode: 'same-origin',
    cache: 'no-store',
    credentials: 'same-origin',
    headers: removeUndefined({
      'Content-Type': hasBody ? 'application/json' : undefined,
      'X-User-ID': appInfo.userId || undefined
    }),
    redirect: 'follow',
    // referrerPolicy is same-origin through the HTTP header anyway (any explicit setting caused an issue in the Pale Moon browser)
    body: hasBody ? JSON.stringify(data) : undefined
  })

  let responseBody
  try {
    responseBody = await response.json()
  } catch (e) {
    console.warn('Failed to parse response body', e)
  }

  if (!response.ok) {
    console.warn(`API error for ${method} ${path}`, responseBody)
    if (response.status === 401 && appInfo.userId) {
      Toast.create({ message: 'Your session expired!', type: 'is-warning' })
      location.assign('/login' + (location.pathname.startsWith('/login') ? '' : `?backTo=${encodeURIComponent(location.pathname + location.query + location.hash)}`))
      await new Promise(resolve => {}) // We hang here intentionally because the redirect should take care of it
    }
    throw new StatusCodeError(response, responseBody)
  }

  return responseBody
}
