<script>
  import { apiCall } from '../lib/api'
  import ErrorBox from '../components/ErrorBox.svelte'
  import Pagination from '../components/Pagination.svelte'
  import DraftRecovery from '../components/DraftRecovery.svelte'
  import Loader from '../components/Loader.svelte'
  import { Field, Input, Button, Toast } from 'svelma'
  import RouterLink from '@spaceavocado/svelte-router/component/link'
  import { onMount } from 'svelte'
  import filesize from 'file-size'
  import { appInfo } from '../lib/appInfo'
  import { softNavigate } from '../router'

  export let page = 0
  export let search = ''

  let loadingPromise

  let data = {
    files: [],
    maxPage: 0,
    count: 0
  }

  let formElement

  let searchInput

  async function load (firstLoad = false) {
    if (!firstLoad) {
      softNavigate({
        name: 'myFiles',
        query: {
          page,
          search: encodeURIComponent(search)
        }
      })
    }

    try {
      loadingPromise = apiCall('GET', '/files', { page, search })
      data = await loadingPromise
      search = data.search
      page = data.page
    } catch (e) {
      console.error('Failed to search', e)
      Toast.create({ message: `Failed to load result: ${e.serverErrorMessage || 'Unknown error'}`, type: 'is-danger' })
    }
  }

  function pageChange () {
    load()
  }

  function submitSearch () {
    search = searchInput
    page = 0
    load()
  }

  onMount(() => {
    searchInput = search
    load(true)
    formElement.querySelector('input').focus()
  })
</script>

<svelte:head>
  <title>My files - CherryShare</title>
</svelte:head>

<style lang="scss">.thumbnail {
  width: 64px;
  height: 64px;
  object-fit: contain;
}

.filename-column {
  min-width: 250px;
}
.filename-column :global(a) {
  display: flex;
  align-items: center;
  word-break: break-word;
}
.filename-column span {
  display: inline-block;
  margin: 6px;
}</style>

<DraftRecovery />

<div class="container py-6">
  <h4 class="title is-4">My files</h4>
  <form on:submit|preventDefault={submitSearch} bind:this={formElement}>
    <Field>
      <Input type="search" placeholder="Search" icon="search" bind:value={searchInput} expanded />
      <p class="control">
        <Button nativeType="submit" type="is-primary">Search</Button>
      </p>
    </Field>
  </form>
  {#await loadingPromise}
    <Loader />
  {:then}
    <p class="has-text-right">{data.count} results</p>
    <table class="table is-fullwidth is-striped is-narrow">
      <thead>
        <tr>
          <th>Filename</th>
          <th>Title</th>
          {#if appInfo.admin}
            <th>Username</th>
          {/if}
          <th>Views</th>
          <th>Downloads</th>
          <th>Size</th>
          <th>Date</th>
        </tr>
      </thead>
      <tbody>
        {#each data.files as file (file.id)}
          <tr>
            <td class="is-vcentered filename-column">
              <RouterLink to={{ name: 'fileDetails', params: { id: file.id } }}>
                <img class="is-inline-vertically-centered thumbnail" src="/t/{file.id}?list=1" alt="Thumbnail" />
                <span>{(file.current && file.current.filename) || '(unknown)'}</span>
              </RouterLink>
            </td>
            <td class="is-vcentered">{file.title || ''}</td>
            {#if appInfo.admin}
              <td class="is-vcentered">{file.username}</td>
            {/if}
            <td class="is-vcentered">{file.views.toLocaleString()}</td>
            <td class="is-vcentered">{file.downloads.toLocaleString()}</td>
            <td class="is-vcentered">{filesize((file.current && file.current.size) || 0).human()}</td>
            <td class="is-vcentered">{new Date(file.current && file.current.uploadDate).toLocaleString()}</td>
          </tr>
        {:else}
          <tr>
            <td colspan={appInfo.admin ? 7 : 6} class="p-6 has-text-centered title is-4">Nothing found...</td>
          </tr>
        {/each}
      </tbody>
    </table>

    <Pagination bind:currentPage={page} maxPage={data.maxPage} on:change={pageChange} />
  {:catch error}
    <ErrorBox text="Something went wrong!">{error}</ErrorBox>
  {/await}
</div>
