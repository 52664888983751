<script>
  export let fileId = ''

  import { Toast, Button, Field, Input, Icon } from 'svelma'
  import MiddleBox from '../components/MiddleBox.svelte'
  import { apiCall } from '../lib/api'
  import { router } from '@spaceavocado/svelte-router'
  import { appInfo } from '../lib/appInfo'
  import { onMount } from 'svelte'
  import { escape } from '../lib/utils'

  let loading = false

  let email = appInfo.email || ''
  let message = ''

  let missingMessage = false

  let formElement

  onMount(() => {
    if (formElement) formElement.querySelector('textarea').focus() // Because autofocus doesn't properly work on <Input>
  })

  async function report () {
    loading = true
    try {
      await apiCall('POST', '/reportAbuse', {
        email,
        fileId,
        message
      })
      Toast.create({ message: 'Thank you, the abuse report was received!', type: 'is-success' })

      $router.push({ name: 'HOME' })
    } catch (e) {
      Toast.create({ message: escape(e.serverErrorMessage) || 'Sending report failed, please try again', type: 'is-danger' })
      missingMessage = e.code === 'missing_message'
      loading = false
    }
  }

  function parseFileId () {
    const matches = fileId.match(/^\s*https:\/\/cherryshare\.at\/(?:f|i|t|files)\/(\w{6})\b/)
    if (matches) fileId = matches[1]
  }
</script>

<svelte:head>
  <title>Report abuse - CherryShare</title>
</svelte:head>

<!-- Note: The is-danger class shouldn't be necessary on the <Input> as per Svelma docs, but it actually is due to a bug: https://github.com/c0bra/svelma/issues/56 -->

<div class="container">
  <MiddleBox>
    <h3 class="title is-3"><Icon icon="flag" size="is-large" /> Report abuse</h3>

    <p class="mb-3">You found some problematic content? Let me know about it here, I will check it and take action as soon as I can. Thank you!</p>

    <form on:submit|preventDefault={report} bind:this={formElement}>
      <fieldset disabled={loading}>
        <Field label="Description of abuse" type={missingMessage ? 'is-danger' : ''} message={missingMessage ? 'The message is required' : ''}>
          <Input type="textarea" maxlength="1000" placeholder="Tell me what happened..." expanded bind:value={message} class={missingMessage ? 'is-danger' : ''} />
        </Field>

        <Field label="Your email address (optional)">
          <Input type="email" bind:value={email} icon="at" />
        </Field>

        <Field label="Offending file ID, if known (optional)">
          <Input type="text" bind:value={fileId} on:change={parseFileId} on:input={parseFileId} icon="file" />
        </Field>

        <br />

        <Field>
          <Button type="is-primary" nativeType="submit" {loading}>Send report</Button>
        </Field>
      </fieldset>
    </form>
  </MiddleBox>
</div>
