'use strict'

// https://spaceavocado.github.io/svelte-router/
import createRouter from '@spaceavocado/svelte-router'
import RouterView from '@spaceavocado/svelte-router/component/view'
import { appInfo } from './lib/appInfo'
import { getStoreValue } from './lib/utils'

// View components
import ViewHome from './views/ViewHome.svelte'
import ViewFileDetails from './views/ViewFileDetails.svelte'
import ViewFileList from './views/ViewFileList.svelte'
import ViewLogin from './views/ViewLogin.svelte'
import ViewLogout from './views/ViewLogout.svelte'
import ViewProfile from './views/ViewProfile.svelte'
import ViewRules from './views/ViewRules.svelte'
import ViewFaq from './views/ViewFaq.svelte'
import ViewAbout from './views/ViewAbout.svelte'
import ViewReportAbuse from './views/ViewReportAbuse.svelte'
import View404 from './views/View404.svelte'

import { Toast } from 'svelma'

const router = createRouter({
  activeClass: 'is-active',
  routes: [
    {
      path: '/',
      name: 'HOME',
      component: ViewHome,
      meta: { guard: 'completeProfile' }
    },
    {
      path: '/files/:id',
      redirect: to => ({
        name: 'fileDetails',
        params: { id: to.params.id }
      })
    },
    {
      path: '/f/:id/:anything',
      redirect: to => ({
        name: 'fileDetails',
        params: { id: to.params.id }
      })
    },
    {
      path: '/f/:id',
      name: 'fileDetails',
      component: ViewFileDetails,
      props: route => ({ fileId: route.params.id })
    },
    {
      path: '/myFiles',
      name: 'myFiles',
      component: ViewFileList,
      props: route => ({ page: route.query.page, search: decodeURIComponent(route.query.search || '') }),
      meta: { guard: 'auth' }
    },
    {
      path: '/login',
      name: 'login',
      component: ViewLogin,
      props: route => ({
        backTo: decodeURIComponent(route.query.backTo || '') || undefined
      }),
      meta: { guard: 'anonymous' }
    },
    {
      path: '/logout',
      name: 'logout',
      component: ViewLogout,
      props: route => ({
        backTo: decodeURIComponent(route.query.backTo || '') || undefined
      }),
      meta: { guard: 'auth' }
    },
    {
      path: '/profile',
      name: 'profile',
      component: ViewProfile,
      meta: { guard: 'auth' }
    },
    {
      path: '/signup',
      name: 'signup',
      component: ViewProfile,
      props: { register: true },
      meta: { guard: 'anonymous' }
    },
    {
      path: '/rules',
      name: 'rules',
      component: ViewRules
    },
    {
      path: '/faq',
      name: 'faq',
      component: ViewFaq
    },
    {
      path: '/about',
      name: 'about',
      component: ViewAbout
    },
    {
      path: '/reportAbuse',
      name: 'reportAbuse',
      component: ViewReportAbuse,
      props: route => ({ fileId: route.query.fileId })
    },
    {
      path: '*',
      component: View404
    }
  ]
})

getStoreValue(router).navigationGuard((from, to, next) => {
  console.log('Navigation attempt:', from, to)
  if (to.meta.guard === 'anonymous' && appInfo.userId) return next(to.query.backTo ? decodeURIComponent(to.query.backTo) : { name: 'HOME' })
  if (to.meta.guard === 'auth' && !appInfo.userId) return next({ name: 'login', query: { backTo: encodeURIComponent(to.fullPath) } })
  if (to.meta.guard === 'admin' && !appInfo.admin) return next({ name: 'HOME' })
  if (to.meta.guard === 'completeProfile' && appInfo.incompleteProfile) {
    if (from && from.name === 'profile') Toast.create({ message: 'Please complete your profile before you upload files!' })
    return next({ name: 'profile' })
  }
  next()
})

getStoreValue(router).onError(error => {
  Toast.create({ message: error, type: 'is-danger' })
  console.error('Router error', error)
})

let shouldFixRouterFlag = true

function softNavigate (route) {
  try {
    shouldFixRouterFlag = false
    getStoreValue(router).push(route)
  } finally {
    setTimeout(() => {
      shouldFixRouterFlag = true
    }, 0)
  }
}

function shouldFixRouter () {
  return shouldFixRouterFlag
}

export { RouterView, router, shouldFixRouter, softNavigate }
