<script>
  export let label
</script>

<div class="level is-mobile">
  <div class="level-left">
    <div class="level-item"><strong>{label}</strong></div>
  </div>
  <div class="level-right">
    <div class="level-item"><slot /></div>
  </div>
</div>
