<script>
  import { onMount, onDestroy } from 'svelte'

  onMount(() => document.body.classList.add('has-lone-hero'))
  onDestroy(() => document.body.classList.remove('has-lone-hero'))
</script>

<section class="hero {$$props.class}">
  <slot />
</section>
