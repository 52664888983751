<script>
  export let src

  import { createEventDispatcher } from 'svelte'
  const dispatch = createEventDispatcher()

  let text = ''

  async function load () {
    try {
      const response = await fetch(src)
      if (!response.ok) throw new Error(`Failed to load, status ${response.status}`)
      text = await response.text()
      dispatch('load', text)
    } catch (e) {
      console.error('Text preview error', e)
      dispatch('error', e)
    }
  }
</script>

<style lang="scss">pre {
  white-space: pre-wrap;
}</style>

{#await load()}
  <!-- nothing -->
{:then}
  <pre class="has-text-left">{text}</pre>
{/await}
