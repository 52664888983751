'use strict'

export function escape (s, nl2br = false) {
  if (!s) return s
  const i = document.createElement('i')
  i.innerText = s
  return nl2br ? i.innerHTML.replace(/\n/g, '<br>') : i.innerHTML
}

export function removeUndefined (object) { // Note that this does mutate the object
  for (const [k, v] of Object.entries(object)) {
    if (v === undefined) delete object[k]
  }
  return object
}

export function getStoreValue (store) {
  let value
  store.subscribe(v => (value = v))()
  return value
}

const ALPHABET = '0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
export function generateRandomString (length) {
  let s = ''
  for (let i = 0; i < length; i++) {
    s += ALPHABET.charAt(Math.floor(Math.random() * ALPHABET.length))
  }
  return s
}
