<script>
  import RouterLink from '@spaceavocado/svelte-router/component/link'
  import { appInfo } from '../lib/appInfo'
  import { onMount } from 'svelte'

  let ul
  let tableOfContents = []

  onMount(() => {
    try {
      for (const li of ul.querySelectorAll('li')) {
        const text = li.querySelector('strong').innerText
        const id = text.replace(/\W/g, '_').replace(/^_+|__+|_+$/g, '').toLowerCase()
        const anchor = document.createElement('a')
        anchor.id = id
        anchor.className = 'anchor'
        li.prepend(anchor)
        tableOfContents.push({ id, text })
      }
      tableOfContents = tableOfContents
      location.replace(location.hash || '#')
    } catch (e) {
      console.error('Error building ToC', e)
    }
  })
</script>

<svelte:head>
  <title>FAQ - CherryShare</title>
</svelte:head>

<style lang="scss">li {
  margin-bottom: 2.5rem;
}

li :global(.anchor) {
  position: relative;
  top: -60px;
}</style>

<div class="container py-3">
  <div class="content">
    <h3>Frequently answered questions</h3>
    {#each tableOfContents as { id, text }}
      <a href="#{id}">{text}</a><br />
    {/each}
    <br/>
    <ul bind:this={ul}>
      <li>
        <strong>Can anyone use CherryShare?</strong><br />
        <br />
        For downloading: yes, anyone can use CherryShare.<br />
        <br />
        For uploading: no, only users in the vicinity of the RPG Maker community are allowed to upload files to CherryShare. You need to create an account to upload files. It's not a strict line there but let's say if you recognize my nickname and you are able to answer the question that is asked during registration, you are eligible. :-)<br />
        <br />
        For more infos, check out the <RouterLink to={{ name: 'rules' }}>rules</RouterLink>.
      </li>
      <li>
        <strong>Is CherryShare free?</strong><br />
        <br />
        Yes, CherryShare is a purely non-commercial project. I don't earn any money from it, not even from advertisements (there are none).
      </li>
      <li>
        <strong>What files can I upload to CherryShare, and is there a storage limit?</strong><br />
        <br />
        Anything that complies with the <RouterLink to={{ name: 'rules' }}>rules</RouterLink>. There is currently no strict limit, it's more of a fair-use thing. The maximum size of a single file is {appInfo.regularMaxFileSizeMb} MiB (this may be changed for specific users).
      </li>
      <li>
        <strong>Can I use direct links to the files, for example to embed an image in a forum?</strong><br />
        <br />
        Images can be linked directly - you will get a hotlink URL in the "link/embed" section for images (supported formats are PNG, JPEG, GIF, APNG, WEBP and SVG). Other files cannot be hotlinked and will always first open the CherryShare page corresponding to the file and can be downloaded from there. Images, audio, video, PDF and OpenDocument files as well as text files with a size of less than 100 KiB can be previewed directly on CherryShare.
      </li>
      <li>
        <strong>Will my files get deleted after a while?</strong><br />
        <br />
        No, until now I never had to introduce a file age limit. Your files currently stay online indefinitely, even if nobody accesses them.<br />
        <br />
        There are two exceptions to this rule: Drafts (files which you started to upload but never clicked on "Continue" at the end) are deleted after 7 days. CherryShare supports a version history, and there <em>previous</em> versions of files are kept for 90 days after a new version was published, then they are deleted. The <em>current</em> version of a file is never deleted though.<br />
        <br />
        Also, inactive user accounts will be deleted after two years, but only if they have never uploaded any file.
      </li>
      <li>
        <strong>What information is visible to other users?</strong><br />
        <br />
        First of all, there is no way to search in other users' files, so to access a file, you need to get the link from the uploader first. If the file is set to "public", then anyone with the link can access the current version of the file as well as its title, description, your username and avatar, the upload date and the number of views and downloads.<br />
        <br />
        The version history of a file as well as the exact dates of the last view and download are visible only to yourself.<br />
        <br />
        If you set a file to "protected", the same rules apply, but only after the user supplied the correct password that you set.<br />
        <br />
        If you set a file to "private", no information about the file is visible to other users.<br />
      </li>
      <li>
        <strong>How does the version history feature work?</strong><br />
        <br />
        You now have the option to update existing files. This can be very useful if you used a CherryShare link to share a project that you made, and you realized you need to make a change. You can now do that without updating the links everywhere.<br />
        <br />
        If you upload a new version, then the previous version will become archived and will show up under "previous versions" at the bottom of the file details page. Only you can access the previous versions. You can download or delete them, and you can also restore a previous version as the current one in case you made a mistake and need to "undo" an update quickly.<br />
        To avoid excessive storage space usage, previous versions of files are retained only for 90 days (counting from the moment they were replaced by a new version). Afterwards, they are automatically deleted.
      </li>
      <li>
        <strong>In the past I often uploaded files without logging in, can I somehow attach them to my account now?</strong><br />
        <br />
        Yes, just contact me and send me a link to the file. Unless there is some indication that it's not really your file, it won't be a problem for me to link "orphaned" files to an account manually.
      </li>
      <li>
        <strong>How are downloads and views counted?</strong><br />
        <br />
        First and foremost, please know that these numbers are not accurate. They are just to judge how often one thing gets accessed compared to another, and to be taken with a grain of salt because it depends on many factors such as how and where a file was shared, what sort of caching mechanims were in effect in case of image hotlink, and so on. Plus, it would be easy to manipulate.<br />
        <br />
        The rules are as follows: Any access using the download button or the "open" link (or clicking on the image preview inside the file page) counts as download. Viewing the file page or loading an image through a hotlink counts as view. A thumbnail impression doesn't count at all. Additionally, impressions by the owner of the file themselves are <em>not</em> counted.<br />
      </li>
      <li>
        <strong>How can I change my avatar?</strong><br />
        <br />
        CherryShare uses Gravatar to fetch an avatar for a given email address. This avatar is the same at any website worldwide which also uses Gravatar. You can customize your avatar on <a href="https://gravatar.com/" target="_blank" rel="noopener">the gravatar.com website</a>.
      </li>
      <li>
        <strong>Do old accounts from the original CherryShare website still work?</strong><br />
        <br />
        Yes, they do. However, in order to continue uploading files, you will have to provide additional information in your profile (in particular the nickname that you use in RPG Maker community forums).<br />
        <br />
        If you didn't log in for 2 years and never uploaded a file, your account will have been deleted though. You can just create a new one.
      </li>
      <li>
        <strong>What happened to the folders feature?</strong><br />
        <br />
        At the moment, the new version of CherryShare doesn't support folder management. This may or may not be added in a future update. However, the associated folders from existing files have been retained (you can see them in the file details page), there is just no way at the moment to modify them or search for them.
      </li>
    </ul>
    <br />
    If you have further questions, you can always contact me of course!
  </div>
</div>
