<script>
  import { Notification } from 'svelma'
  import shareFlardLogo from '../images/shareflard.png'

  function removeHash () {
    if (location.hash.toLowerCase() === '#shareflard') {
      history.replaceState({}, document.title, location.href.replace(/#.*$/, ''))
    }
  }
</script>

<style lang="scss">section :global(.notification) {
  margin: 0;
}</style>

<section>
  <Notification on:close={removeHash}>
    <h1 class="title has-text-centered is-1">
      <img src={shareFlardLogo} alt="ShareFlard" width="186" height="151" class="is-inline-vertically-centered" /> will never be forgotten!
    </h1>
  </Notification>
</section>
