<script>
  import RouterLink from '@spaceavocado/svelte-router/component/link'
  import { Icon } from 'svelma'
</script>

<footer class="footer">
  <div class="content has-text-centered">
    <p>
      <strong>CherryShare</strong> by <RouterLink to={{ name: 'about' }}>David "Cherry" Trapp</RouterLink> (<Icon pack="fab" icon="twitter" /><a href="https://twitter.com/CherryDT">@CherryDT</a>) - The RPG Maker community's friendly neighborhood hoster since 2009.
    </p>
    <p>
      This is a personal, non-commercial website whose content is mostly user-generated. If you spot anything that breaks the rules or seems to be otherwise problematic, <RouterLink to={{ name: 'reportAbuse' }}>report</RouterLink> it please!
    </p>
  </div>
</footer>
