<script>
  export let text = 'Loading...'

  import MiddleBox from './MiddleBox.svelte'
  import { Icon } from 'svelma'
</script>

<MiddleBox class="has-text-centered">
  <p class="m-3"><Icon icon="spinner" size="is-large" customClass="fa-pulse" /></p>
  <h2 class="subtitle">{text}</h2>
</MiddleBox>
