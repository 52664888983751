<script>
  import { generateRandomString } from '../lib/utils'

  export let files
  export let finishedFiles
  export let uploadInProgress
  export let multiUploadId = generateRandomString(6)
  export let disabled = false
  export let originalFile = null
  export let removeFilesOnUnmount = false

  import Dropzone from 'dropzone'
  import { appInfo } from '../lib/appInfo'
  import { onMount, onDestroy } from 'svelte'
  import { Icon, Toast } from 'svelma'
  import 'dropzone/dist/dropzone.css'
  import { apiCall } from '../lib/api'

  Dropzone.autoDiscover = false

  files = []
  finishedFiles = []
  uploadInProgress = false

  let element
  let dropzone

  function paste (event) {
    if (disabled) return
    const items = (event.clipboardData || event.originalEvent.clipboardData).items
    for (const item of items) {
      if (item.kind === 'file') {
        // adds the file to your dropzone instance
        dropzone.addFile(item.getAsFile())
      }
    }
  }

  onMount(() => {
    dropzone = new Dropzone(element, {
      // https://www.dropzonejs.com/#configuration-options
      url: '/api/uploads/' + multiUploadId, // can be a function files => url
      maxFilesize: appInfo.maxFileSizeMb,
      clickable: true,
      thumbnailMethod: 'contain',
      filesizeBase: 1024,
      maxFiles: originalFile ? 1 : appInfo.maxNumberOfFiles,
      headers: { 'X-User-ID': appInfo.userId },
      addRemoveLinks: true,
      parallelUploads: 4,
      timeout: 3600000,
      accept: (file, done) => {
        if (disabled) {
          done('Files cannot be added right now')
        } else {
          done()
        }
      }
    })

    dropzone.on('error', (file, error, fromXhr) => {
      console.error('Upload error', error, file)
      if (file.status !== 'canceled') {
        Toast.create({ message: `Cannot upload ${file.name}: ${error.error || error.message || JSON.stringify(error)}`, type: 'is-danger' })
      }
    })

    dropzone.on('success', (file, response) => {
      console.log('File uploaded', response, file)
      file.serverFileId = response.fileId
    })

    dropzone.on('removedfile', file => {
      console.log('Removed file', file)
      if (file.serverFileId) {
        apiCall('DELETE', `/files/${file.serverFileId}`).catch(e => {
          console.error('Error deleting unfinished upload', e, file)
          Toast.create({ message: `File ${file.name} could not be deleted from server: ${e.serverErrorMessage || 'Unknown error'}`, type: 'is-danger' })
        })
      }
    })

    for (const event of ['success', 'error', 'addedfile', 'removedfile', 'processing', 'canceled', 'queuecomplete']) {
      dropzone.on(event, () => {
        uploadInProgress = dropzone.files.some(file => [Dropzone.QUEUED, Dropzone.UPLOADING].includes(file.status))
        files = dropzone.files
        finishedFiles = dropzone.files.filter(file => file.status === Dropzone.SUCCESS)
      })
    }

    document.addEventListener('paste', paste)
  })

  onDestroy(() => {
    document.removeEventListener('paste', paste)

    if (removeFilesOnUnmount) dropzone.removeAllFiles(true)

    dropzone.disable()
    dropzone = null
  })
</script>

<style lang="scss">/* Buttons require more space */
.dropzone {
  background: rgba(216, 216, 216, 0.15);
  border-style: dashed;
  border-radius: 20px;
  min-height: 225px;
  position: relative;
}
.dropzone :global(.dz-error-message) {
  background: #f79c12;
  transform: translateY(1rem);
}
.dropzone :global(.dz-error-message):after {
  border-bottom-color: #f79c12;
}
.dropzone :global(.dz-preview) {
  background: transparent !important;
  border-radius: 20px;
}
.dropzone :global(.dz-preview).dz-error :global(.dz-image) {
  opacity: 0.7;
}
.dropzone :global(.dz-preview) :global(.dz-progress) {
  top: calc(50% + 24px);
}
.dropzone :global(.dz-image) {
  display: flex;
  justify-content: center;
  align-items: center;
}
.dropzone :global(.dz-image) :global(img) {
  border-radius: 20px;
}
.dropzone :global(.dz-error-mark svg [fill]) {
  fill: #f79c12;
}
.dropzone :global(.dz-message) {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0;
}
.dropzone.dz-started :global(.dz-message) {
  display: none;
}

:global(.hero.is-primary) .dropzone {
  border-color: white;
}
:global(.hero.is-primary) .dropzone :global(.dz-remove) {
  color: white;
}</style>

<div bind:this={element} class="dropzone {$$props.class}">
  <div class="dz-message">
    <Icon icon="upload" size="is-large" />
    <p class="m-2">Drop {originalFile ? 'new file' : 'files'} here, paste image with Ctrl+V or click to upload</p>
    <p>Max. size: {appInfo.maxFileSizeMb} MiB</p>
  </div>
</div>
